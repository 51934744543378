// import axios from 'axios';
//
// // Загружаем переменные окружения
// const authorizationToken = process.env.REACT_APP_AUTHORIZATION_TOKEN;
// const apiUrl = process.env.REACT_APP_API_URL;
// const WEBAPP_ACCESS_TOKEN = process.env.REACT_APP_WEBAPP_ACCESS_TOKEN;
//
// // Создание экземпляра axios с базовой конфигурацией
// const apiClient = axios.create({
//     baseURL: apiUrl, // базовый URL для API
//     headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${authorizationToken}`, // Получение токена из переменных окружения
//     }
// });
//
// // Интерцептор для добавления токена из localStorage в заголовки каждого запроса
// apiClient.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem('token'); // Получаем токен из localStorage
//         if (token) {
//             config.headers['token'] = token; // Добавляем токен в заголовок
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );
//
// // Функция для обновления токена
// const refreshAuthToken = async () => {
//     const refreshToken = localStorage.getItem('refresh_token'); // Получаем refresh token из localStorage
//
//     console.log(refreshToken, 'refreshToken')
//     if (!refreshToken) {
//         console.error('No refresh token found');
//         return null;
//     }
//
//     try {
//         const response = await axios.post(`${apiUrl}/auth/refresh`, {}, {
//             headers: {
//                 'token': refreshToken
//             }
//         });
//
//         console.log(response.data.data, 'response.data.data')
//         const { token, refresh_token: newRefreshToken } = response.data.data;
//
//         if (newRefreshToken) {
//             // Сохраняем новый токен и refreshToken в localStorage
//             localStorage.setItem('token', token);
//             localStorage.setItem('refresh_token', newRefreshToken);
//
//             return token;
//         } else {
//             return '';
//         }
//
//     } catch (error) {
//         console.error('Error refreshing token:', error);
//         return null;
//     }
// };
//
// // Интерцептор для обработки ошибок
// apiClient.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config;
//
//         // Если запрос вернул ошибку 401 (Unauthorized) и это не запрос на обновление токена
//         if (error.response && error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;
//
//             // Попробуем обновить токен
//             const newToken = await refreshAuthToken();
//
//             if (newToken) {
//                 // Обновляем заголовок с новым токеном
//                 originalRequest.headers['token'] = newToken;
//
//                 // Повторяем оригинальный запрос с новым токеном
//                 return apiClient(originalRequest);
//             }
//         }
//
//         return Promise.reject(error);
//     }
// );
//
// export default apiClient;


import axios from 'axios';

// Загружаем переменные окружения
const authorizationToken = process.env.REACT_APP_AUTHORIZATION_TOKEN;
const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlForGetToken = process.env.REACT_APP_GET_TOKEN_API_URL;

// Создание экземпляра axios с базовой конфигурацией
const apiClient = axios.create({
    baseURL: apiUrl, // базовый URL для API
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authorizationToken}`, // Получение токена из переменных окружения
    }
});

// Интерцептор для добавления токена из localStorage в заголовки каждого запроса
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        if (token) {
            config.headers['token'] = token; // Добавляем токен в заголовок
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Функция для получения нового токена через initData с использованием fetch
const fetchTokenWithInitData = async () => {
    console.log(window?.Telegram, ' window.Telegram');
    const webApp = window?.Telegram?.WebApp;

    const localInitData = '';

    // const localInitData = process.env.NODE_ENV === 'development' ?
    //     "query_id%3DAAGJnFYyAAAAAImcVjKsfOni%26user%3D%257B%2522id%2522%253A844536969%252C%2522first_name%2522%253A%2522%25D0%2590%25D1%2580%25D1%2582%25D0%25B5%25D0%25BC%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522tyom_webdev%2522%252C%2522language_code%2522%253A%2522ru%2522%252C%2522allows_write_to_pm%2522%253Atrue%252C%2522photo_url%2522%253A%2522https%253A%255C%252F%255C%252Ft.me%255C%252Fi%255C%252Fuserpic%255C%252F320%255C%252Fy0Jl7HqKzSDLmO7GdqpHsYn9ykIEpPpX6fbe4R5VVXk.svg%2522%257D%26auth_date%3D1738319690%26signature%3D6FEeSdU7vyiJsU2VOcbhMbKr7Id8wM0dJFG9fmUoVGxZMEZmk-P2fdtKegU_TU_vuZG1l2Q98HqV8D0LwypbDw%26hash%3Dedba08f93f4553db7187e35bd77732c4326fe54eecc8b19c6a130a45a3c251df"
    //     : ''
    // ;

    let encodedInitData = '';

    if (!webApp || !webApp?.initData) {
        console.error('WebApp initData not available');
        encodedInitData = localInitData;
    } else {
        encodedInitData = encodeURIComponent(webApp?.initData);
    }

    try {

        const response = await fetch(`${apiUrlForGetToken}/getTelegramUser`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ initData:encodedInitData }), // Отправляем initData в теле запроса
        });


        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data && data.token) {
            const { token } = data;

            // Сохраняем новый токен в localStorage
            localStorage.setItem('token', token);

            return token;
        } else {
            console.error('Token not found in response');
            return null;
        }
    } catch (error) {
        console.error('Error fetching token with initData:', error);
        return null;
    }
};

// Интерцептор для обработки ошибок
apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Если запрос вернул ошибку 401 (Unauthorized) и это не запрос на обновление токена
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Попробуем получить новый токен через initData
            const newToken = await fetchTokenWithInitData();

            if (newToken) {
                // Обновляем заголовок с новым токеном
                originalRequest.headers['token'] = newToken;

                // Повторяем оригинальный запрос с новым токеном
                return apiClient(originalRequest);
            }
        }

        return Promise.reject(error);
    }
);

export default apiClient;
