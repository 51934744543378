import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import Loader from "../../../components/Loader";

import Header from "../../../components/Header";
import {AutoIcon, LocationIcon, LogoIcon, RightArrowIcon, UserIcon} from "../../../assets/svg/icons";
import {useSingleData} from "../../../context/SingleContext";
import topImage from "../../../assets/img/main/top2.png";
import {useUserOrders} from "../../../hooks/useUserOrders";

function RegistrationsForServicesSingle() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const { selectedSingleData, setSelectedSingleData } = useSingleData(); // Достаем функцию для установки выбранного заказа
    const {
        orderCoordinates,
        orderCoordinatesLoading,
        orderCoordinatesError,
        fetchOrderCoordinates
    } = useUserOrders(); // Получаем выбранный заказ из контекста



    const navigate = useNavigate();

    const [isOpenAssets,setIsOpenAssets] = useState(false);
    const [loading,setLoading] = useState(true);

    useEffect(()=>{

        if (selectedSingleData) {
            fetchOrderCoordinates(selectedSingleData?.shopId)

            console.log(selectedSingleData, 'selectedSingleData')
            setTimeout(()=>{
                setLoading(false);
            }, 1000)
        }

    }, [selectedSingleData])


    useEffect(()=>{
        if (orderCoordinates) {
            console.log(orderCoordinates, 'orderCoordinates')
        }
    }, [orderCoordinates])



    function formatDateToRussian(dateStr) {
        // Преобразуем строку в объект Date
        const dateObj = new Date(dateStr);

        // Массив названий месяцев на русском
        const months = [
            "января", "февраля", "марта", "апреля", "мая", "июня",
            "июля", "августа", "сентября", "октября", "ноября", "декабря"
        ];

        // Получаем день, месяц и год
        const day = dateObj.getDate();
        const month = months[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        // Форматируем строку
        return `${day} ${month} ${year} г.`;
    }


    function getDayOfWeekInRussian(dateStr) {
        // Преобразуем строку в объект Date
        const dateObj = new Date(dateStr);

        // Массив названий дней недели на русском
        const daysOfWeek = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];

        // Получаем название дня недели
        return daysOfWeek[dateObj.getDay()];
    }



    // const handleNavigation = () => {
    //     // const address = encodeURIComponent(selectedSingleData?.address);
    //
    //     const lat = orderCoordinates?.data?.xCoord
    //     const lng = orderCoordinates?.data?.yCoord
    //
    //     console.log(lat, lng, 'latlng');
    //     window.open(`https://yandex.com/maps/org/koleso_ru/1190483383/?ll=${lat},${lng}&z=15`, '_blank');
    //
    // };

    const handleNavigation = () => {

        console.log(orderCoordinates, 'orderCoordinates');


        // const lat = orderCoordinates?.data?.xCoord
        // const lng = orderCoordinates?.data?.yCoord
        // window.open(`https://yandex.com/maps/org/koleso_ru/1190483383/?ll=${lat},${lng}&z=15`, '_blank');
        // window.open(`https://yandex.ru/maps/?ll=${lng},${lat}&z=15`, '_blank');
        window.open(orderCoordinates?.data?.yandexMapUrl, '_blank');

    };


    if (loading) {
        return (
            <Loader/>
        )
    }

    return (
        <div className="wrapper wrapper-flex">

            <Header/>

            {/*<Link to={'/main'} className="top-block-logo">*/}
            {/*    <LogoIcon/>*/}
            {/*</Link>*/}

            <div className={'list-wrapper'} style={{flex:1, marginTop:20, overflow:'scroll'}}>

                <Link to={'/registrations-for-service-single?id=1'} className={'list-item'} style={{background: 'unset', padding: 0}}>
                    <div className={'list-item-center'}>
                        <span style={{fontSize:20, fontWeight: 700}}>Шиномонтаж</span>
                    </div>
                    <div className={'list-item-bottom'}>
                        <span>{formatDateToRussian(selectedSingleData?.date?.date)} {getDayOfWeekInRussian(selectedSingleData?.date?.date)}</span>
                        <span className={'yellow-time'}>{selectedSingleData?.date?.time}</span>
                    </div>

                </Link>

                <h1 className={'reg-for-services-title'}>Адрес центра</h1>

                <div className={'reg-for-services-address'}>
                    {selectedSingleData?.shopName}
                </div>

                <button onClick={handleNavigation} className={'build-a-route'}>
                    Построить маршрут
                </button>

                <h1 className={'reg-for-services-title'}>Гос. номер:</h1>
                <p className={'reg-for-services-val'} >{selectedSingleData?.gtd}</p>


                <h1 className={'reg-for-services-title'}>Автомобиль:</h1>
                <p className={'reg-for-services-val'} >{selectedSingleData?.carName}</p>

                <h1 className={'reg-for-services-title'}>№ партии хранения:</h1>

                <p className={'reg-for-services-val'} >{selectedSingleData?.ox.length > 0 ? selectedSingleData?.ox[0] : 'Не указан'}</p>

            </div>

            <div className={'bottom-btns-wrapper'}>
                <button
                    onClick={() => {
                        navigate(-1);
                        // navigate('/main')
                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>
                <button
                    onClick={() => {
                        let pageUrl_ = `https://koleso.ru/rec/?widgetCancel=${selectedSingleData?.code}`;
                        window.Telegram.WebApp.openLink(pageUrl_);
                    }}
                    className={'red-btn'}
                >
                    Изменить/Отменить
                </button>
            </div>


        </div>
    );
}

export default RegistrationsForServicesSingle;
