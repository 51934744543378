import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import Loader from "../../components/Loader";
import useTranslation from "../../hooks/useTranslation";
import Header from "../../components/Header";
import useMyCars from "../../hooks/useMyCars";
import autoImage from "../../assets/img/main/auto.png";
import {BottomArrowIcon, FeedBackSuccessIcon, LogoIcon, PlusIcon} from "../../assets/svg/icons";
import FeedbackForm from "../../components/FeedbackForm";
import AddCarForm from "./AddCarForm";

function MyCars() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();
    const {
        myCarsAnswer, loadingMyCars, errorMyCars, fetchUserCars,

        myCarsDeleteAnswer,
        myCarsDeleteLoading,
        myCarsDeleteError,
        fetchDeleteUserCars
    } = useMyCars();

    const [deleteQuestionModal, setDeleteQuestionModal] = useState(false);
    const [deleteAutoRn, setDeleteAutoRn] = useState(null);

    const [successModal, setSuccessModal] = useState(false);
    const [successDeleteModal, setSuccessDeleteModal] = useState(false);
    const [isOpenCarId,setIsOpenCarId] = useState(null);
    const [noData, setNoData] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{
        fetchUserCars()
    }, [])


    useEffect(() => {
        if (myCarsDeleteAnswer) {
            setDeleteQuestionModal(false);
            setSuccessModal(true)
            fetchUserCars()

            setTimeout(()=>{
                fetchUserCars()
            }, 100)
        }
    }, [myCarsDeleteAnswer])

    useEffect(() => {
        if (myCarsDeleteError) {
            console.log(myCarsDeleteError, 'myCarsDeleteError')
        }
    }, [myCarsDeleteError])

    useEffect(()=>{
        if (myCarsAnswer) {
            if (myCarsAnswer?.data?.length > 0) {
                setNoData(false)
            } else {
                setNoData(true)
            }
            console.log(myCarsAnswer, 'myCarsAnswer')
        }
    }, [myCarsAnswer])

    // if (loadingMyCars) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (
        <div className="wrapper " style={{padding: 0}}>

            <Header autoImage={autoImage}/>

            <div
                className={'padding-block'}
                style={{
                    flex: 1,
                    borderRadius: "10px 10px 0 0 ",
                    position: 'relative',
                    top: -24,
                    background: "white",
                    paddingBottom: window.Telegram?.WebApp?.platform != 'ios' ? 70 : 0
                }}
            >
                <div className={'top-title-wrapper'}>

                    {noData ?
                        <div className={'top-title-text'} style={{marginLeft:0}}>
                            Пока нет авто
                        </div>
                        :
                        <div className={'top-title-text'} style={{marginLeft:0}}>
                            Мои авто
                        </div>
                    }

                </div>

                <div className={'list-wrapper'} style={{marginBottom: 140}}>

                    {/*{myCarsAnswer?.data && myCarsAnswer?.data.map((item, index) => {*/}
                    {/*    return (*/}
                    {/*        <div className={'list-item'}>*/}
                    {/*            <div className={'list-item-center'}>*/}
                    {/*                <span>{item?.carMark}</span>*/}
                    {/*            </div>*/}
                    {/*            <div className={'list-item-bottom'}>*/}
                    {/*                <span>Гос. номер:</span>*/}
                    {/*                <span>{item?.gtd}</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*    )*/}
                    {/*})}*/}

                    {noData ?
                        <>
                            <img style={{width: '100%'}} src={require('../../assets/img/myauto/noauto.png')} alt=""/>
                        </>
                        :
                        <>
                            {myCarsAnswer?.data && myCarsAnswer?.data.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            let id = index;
                                            setIsOpenCarId(isOpenCarId == id ? null : id)
                                        }}
                                        className={`list-item ${isOpenCarId == index ? 'is-open' : ''}`}
                                        style={{padding: 0}}
                                    >

                                        <div className={'list-item-wrapper'}>
                                            <div className={'list-item-center'}>
                                                <span>{item?.carMark}</span>
                                            </div>

                                            <div className={'list-item-bottom'}>
                                                <span>Гос. номер:</span>
                                                <span>{item?.gtd}</span>
                                            </div>

                                            <BottomArrowIcon className={'bottom-arrow'}/>
                                        </div>

                                        <div className={'list-item-bottom-hide-block'}>
                                            <button
                                                onClick={(e)=>{
                                                    e.stopPropagation()
                                                    setDeleteQuestionModal(true)
                                                    setDeleteAutoRn(item.autoRn)
                                                    console.log(item, 'item')
                                                }}
                                            >
                                                Удалить авто
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}


                        </>
                    }


                </div>


                {noData &&
                    <FeedbackForm title={'Насколько удобен личный кабинет?'} pageName={'Мои авто'}/>
                }
            </div>


            <div className={'bottom-btns-wrapper'}>
                <button
                    onClick={() => {
                        // navigate(-1)
                        navigate('/main');

                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>

                <button
                    onClick={() => {
                        setIsOpen(true)
                    }}
                    className={'red-btn'}

                >
                    <PlusIcon style={{marginRight: 10}}/>
                    Добавить ещё авто
                </button>

            </div>

            <AddCarForm
                isOpen={isOpen}
                setOpen={setIsOpen}
                updateCarList={()=>{
                    fetchUserCars()
                    setSuccessDeleteModal(true)
                }}
            />

            {deleteQuestionModal &&

                <div className={'success-wrapper-modal'}>
                    <div className={'delete-car-success-wrapper'}>
                        {/*<span className={'delete-car-success-wrapper-span1'}>Сообщение отправлено</span>*/}
                        {/*<FeedBackSuccessIcon/>*/}
                        <span className={'delete-car-success-wrapper-span2'}>
                           Удалить автомобиль?
                       </span>
                        <div className={'delete-car-quest-btn-wrapper'}>
                            <button
                                onClick={() => {
                                    if (deleteAutoRn) {
                                        fetchDeleteUserCars(deleteAutoRn);
                                    }
                                }}
                            >
                                Да
                            </button>
                            <button
                                onClick={() => {
                                    setDeleteQuestionModal(false);
                                }}
                            >
                                Отменить
                            </button>
                        </div>

                    </div>
                </div>
            }

            {successModal &&

               <div className={'success-wrapper-modal'}>
                   <div className={'delete-car-success-wrapper'}>
                       {/*<span className={'delete-car-success-wrapper-span1'}>Сообщение отправлено</span>*/}
                       <FeedBackSuccessIcon/>
                       <span className={'delete-car-success-wrapper-span2'}>
                           Ваш автомобиль был успешно удален!
                       </span>
                       <button onClick={() => { setSuccessModal(false); }}>Хорошо</button>
                   </div>
               </div>
            }

            {successDeleteModal &&

               <div className={'success-wrapper-modal'}>
                   <div className={'delete-car-success-wrapper'}>
                       {/*<span className={'delete-car-success-wrapper-span1'}>Сообщение отправлено</span>*/}
                       <FeedBackSuccessIcon/>
                       <span className={'delete-car-success-wrapper-span2'}>
                           Ваш автомобиль был успешно добавлен!
                       </span>
                       <button onClick={() => { setSuccessDeleteModal(false); }}>Хорошо</button>
                   </div>
               </div>
            }

        </div>
    );
}

export default MyCars;
