import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";


const SettingsBottomSheet = ({
                                 isOpen, setOpen, modalType,
                                 smsMailingsEnable, phoneCallEnable, emailMailingsEnable, messengerMailingsEnable,
                                 setPhoneCallAllowed, setSmsMailingsAllowed, setEmailMailingsAllowed, setMessengerMailingsAllowed,
                                 setPhoneCallEnable, setSmsMailingsEnable, setEmailMailingsEnable, setMessengerMailingsEnable,
                                 fetchUpdateUserSettings
}) => {
    const navigate = useNavigate();
    const ref = useRef();
    const { executeRecaptcha } = useGoogleReCaptcha();

    // const [selectedReason, setSelectedReason] = useState(null)
    const [height, setHeight] = useState(310);

    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [image, setImage] = useState(null)

    const [reasonsList, setReasonsList] = useState([
        {id: 2, title: 'Отказаться'},
        {id: 1, title: 'Отмена'},
    ]);

    useEffect(()=> {

        // setSelectedReason(null);

        if (isOpen) {
            if (modalType == 'phone') {
                setTitle('Телефонные звонки')
                setDesc('Оставайтесь с нами: узнайте спецпредложения и важные напоминания. Позвоним не чаще 2-3 раз в год.')
                setImage(require('../../../../assets/img/settings/phone.png'))
            } else if (modalType == 'sms') {
                setTitle('СМС')
                setDesc('Оставайтесь с нами: будте в курсе акций и скидок. Пишем не чаще раза в месяц.');
                setImage(require('../../../../assets/img/settings/sms.png'))
            } else if (modalType == 'email') {
                setTitle('Email')
                setDesc('Оставайтесь с нами: получайте эксклюзивные скидки, полезные советы и первыми узнавайте о новинках. ');
                setImage(require('../../../../assets/img/settings/email.png'))
            } else if (modalType == 'messenger') {
                setTitle('Мессенджер')
                setDesc('Оставайтесь с нами: получайте спецпредложения и важные напоминания напрямую в мессенджер. Пишем не чаще раза в месяц.');
                setImage(require('../../../../assets/img/settings/messanger.png'))
            }
        }


        console.log(modalType, 'modalType')
    }, [isOpen])

    const snapTo = (i) => ref.current?.snapTo(i);


    if (!isOpen) {
        return  null
    }
    return (

        <div className={'cancel-order-bottomshet-wrapper modal-wrapper'}>
            <div className={'cancel-order-bottomshet-container modal-container'}>


                <div>
                    <h1 className={'cancel-order-bottomshet-title'}>
                        {title}
                    </h1>

                    {image &&
                        <img className={'cancel-order-image'}  src={image} alt=""/>
                    }

                    <p className={'cancel-order-desc'}>{desc}</p>
                </div>



                <div>
                    <p className={'cancel-order-subtitle'}>Всё равно отказаться?</p>
                    <div className={'reasons-list-wrapper'} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        {reasonsList?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={async () => {

                                        let selectedReason = item?.id

                                        if (selectedReason == 2) {

                                            if (!executeRecaptcha) {
                                                console.error("Recaptcha not загружена");
                                                return;
                                            }
                                            const captchaTokenGoogle = await executeRecaptcha("submit");

                                            let consentsData = {
                                                consents: {},
                                                captchaTokenGoogle: captchaTokenGoogle
                                            }

                                            switch (modalType) {
                                                case 'phone':
                                                    setPhoneCallEnable(false);
                                                    consentsData.consents.consent_to_call =  0;
                                                    break;
                                                case 'sms':
                                                    setSmsMailingsEnable(false);
                                                    consentsData.consents.consent_to_sms =  0;
                                                    break;
                                                case 'email':
                                                    setEmailMailingsEnable(false);
                                                    consentsData.consents.consent_to_email = 0;
                                                    break;
                                                case 'messenger':
                                                    setMessengerMailingsEnable(false);
                                                    consentsData.consents.consent_to_msgrs = 0;
                                                    break;
                                                default:
                                                    break;
                                            }

                                            console.log(consentsData, 'consentsData')
                                            await fetchUpdateUserSettings(consentsData)
                                            setOpen(false)
                                        } else {
                                            setOpen(false)
                                        }
                                    }}
                                    className={`reasons-list-item reasons-list-item-btn-${item?.id == 2 ? 'red' : ''}`}
                                    style={{marginBottom: 0}}
                                >
                                    <span>{item?.title}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SettingsBottomSheet
