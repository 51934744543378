import apiClient from '../apiClient';

// Функция для получения профиля пользователя
export const getUserProfile = async () => {
    try {
        const response = await apiClient.post('/user/profile', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};

export const updateUserProfileApi = async (payload) => {
    try {
        const response = await apiClient.post(`/user/profile/update`, payload);
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error updating block status:', error);
        throw error;
    }
};
