import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import Loader from "../../components/Loader";
import useTranslation from "../../hooks/useTranslation";
import Header from "../../components/Header";
import useMyCars from "../../hooks/useMyCars";
import topImage from "../../assets/img/main/top2.png";
import {BottomArrowIcon, LogoIcon, PlusIcon} from "../../assets/svg/icons";
import FeedbackForm from "../../components/FeedbackForm";
import {Switch} from "@mui/material";
import SettingsBottomSheet from "./include/SettingsBottomSheet";
import useSettings from "../../hooks/useSettings";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

function Settings() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { user, triggerVibration, webApp  } = useTelegram();
    const {
        settingsAnswer,
        settingsLoad,
        settingsError,
        fetchUserSettings,


        settingsUpdateAnswer,
        settingsUpdateLoad,
        settingsUpdateError,
        fetchUpdateUserSettings
    } = useSettings();

    const navigate = useNavigate();
    const {translation} = useTranslation();

    // const { myCarsAnswer, loadingMyCars, errorMyCars, fetchUserCars } = useMyCars();

    const [firstLoad,setFirstLoad] = useState(true);

    const [phoneCallAllowed,setPhoneCallAllowed] = useState(false);
    const [phoneCallEnable,setPhoneCallEnable] = useState(false);

    const [smsMailingsAllowed,setSmsMailingsAllowed] = useState(false);
    const [smsMailingsEnable, setSmsMailingsEnable] = useState(false);

    const [emailMailingsAllowed,setEmailMailingsAllowed] = useState(false);
    const [emailMailingsEnable, setEmailMailingsEnable] = useState(false);

    const [messengerMailingsAllowed,setMessengerMailingsAllowed] = useState(false);
    const [messengerMailingsEnable, setMessengerMailingsEnable] = useState(false);

    const [modalType, setModalType] = useState(null);
    const [isOpen, setIsOpen] = useState(null);


    useEffect(()=>{
        fetchUserSettings()
    }, [])

    useEffect(()=>{
        if (settingsAnswer) {
            console.log(settingsAnswer, 'settingsAnswer')

            const handleConsent = (value, setAllowed, setEnable) => {
                if (value == null) {
                    setAllowed(false);
                    setEnable(false);
                } else {
                    setAllowed(true);
                    setEnable(value === 1);
                }
            };

            handleConsent(settingsAnswer?.data?.consent_to_sms, setSmsMailingsAllowed, setSmsMailingsEnable);
            handleConsent(settingsAnswer?.data?.consent_to_call, setPhoneCallAllowed, setPhoneCallEnable);
            handleConsent(settingsAnswer?.data?.consent_to_email, setEmailMailingsAllowed, setEmailMailingsEnable);
            handleConsent(settingsAnswer?.data?.consent_to_msgrs, setMessengerMailingsAllowed, setMessengerMailingsEnable);

            // setSmsMailingsAllowed(settingsAnswer?.data?.consent_to_sms ? true: false)
            // setPhoneCallAllowed(settingsAnswer?.data?.consent_to_call ? true: false)
            // setEmailMailingsAllowed(settingsAnswer?.data?.consent_to_email ? true: false)
            // setMessengerMailingsAllowed(settingsAnswer?.data?.consent_to_msgrs ? true: false)
            // setSmsMailingsEnable(settingsAnswer?.data?.consent_to_sms ? true: false)
        }
    }, [settingsAnswer])

    useEffect(()=>{
        if (settingsUpdateAnswer) {
            console.log(settingsUpdateAnswer, 'settingsUpdateAnswer')
            setFirstLoad(false);
            fetchUserSettings();
        }
    }, [settingsUpdateAnswer])

    useEffect(()=>{
        if (settingsUpdateError) {
            console.log(settingsUpdateError, 'settingsUpdateError')
        }
    }, [settingsUpdateError])


    const handleUpdateSettings = async (type) => {

        if (!executeRecaptcha) {
            console.error("Recaptcha not загружена");
            return;
        }
        const captchaTokenGoogle = await executeRecaptcha("submit");

        let consentsData = {
            consents: {

            },
            captchaTokenGoogle: captchaTokenGoogle
        }

        switch (type) {
            case 'phone':
                consentsData.consents.consent_to_call = 1;
                break;
            case 'sms':
                consentsData.consents.consent_to_sms = 1;
                break;
            case 'email':
                consentsData.consents.consent_to_email = 1;
                break;
            case 'messenger':
                consentsData.consents.consent_to_msgrs = 1;
                break;
            default:
                break;
        }


        console.log(consentsData, 'consentsData')
        await fetchUpdateUserSettings(consentsData)

    }

    if ( settingsLoad && firstLoad) {
        return (
            <Loader/>
        )
    }


    return (
        <div className="wrapper wrapper-flex" style={{padding: 0}}>

            <Header autoImage={topImage}/>

            <div
                className={'padding-block'}
                style={{
                    flex: 1,
                    borderRadius: "10px 10px 0 0 ",
                    position: 'relative',
                    top: -25,
                    background: "white",
                    paddingBottom: 110
                }}
            >
                <div className={'top-title-wrapper'}>

                    <div className={'top-title-text'} style={{marginLeft: 0}}>
                        Настройки
                    </div>
                </div>

                <div className={'list-wrapper'}>

                    {/*Телевонные звонки*/}
                    <div className={`list-item is-open`} style={{padding: 0}}>

                        <div className={'list-item-wrapper'}>
                            <div className={'list-item-center'}>
                                <span>Телефонные звонки</span>
                            </div>

                        </div>

                        <div className={'list-item-bottom-hide-block settings'} style={{display: "flex", justifyContent: "space-between", alignItems:'center'}}>

                            {phoneCallAllowed ?
                                <>
                                    <p>Даю согласие на телефонные звонки</p>
                                    <Switch
                                        color={'success'}
                                        checked={phoneCallEnable}
                                        onChange={(e) => {
                                            if (phoneCallEnable) {
                                                setIsOpen(true)
                                                setModalType('phone')
                                            } else {
                                                setPhoneCallEnable(prevState => !prevState)
                                                handleUpdateSettings('phone')

                                            }

                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                </>
                                :
                                <>
                                    <p>Еще не назначено</p>
                                    <button
                                        onClick={() => {
                                            handleUpdateSettings('phone')
                                            setPhoneCallAllowed(true)
                                            setPhoneCallEnable(true);
                                        }}
                                    >
                                        Назначить
                                    </button>
                                </>
                            }
                        </div>
                    </div>

                    {/*СМС-рассылки*/}
                    <div className={`list-item is-open`} style={{padding: 0}}>

                        <div className={'list-item-wrapper'}>
                            <div className={'list-item-center'}>
                                <span>СМС-рассылки</span>
                            </div>

                        </div>

                        <div className={'list-item-bottom-hide-block settings'} style={{display: "flex", justifyContent: "space-between", alignItems:'center'}}>

                            {smsMailingsAllowed ?
                                <>
                                    <p>Даю согласие на смс-рассылки</p>
                                    <Switch
                                        color={'success'}
                                        checked={smsMailingsEnable}
                                        onChange={(e) => {
                                            if (smsMailingsEnable) {
                                                setIsOpen(true)
                                                setModalType('sms')
                                            } else {
                                                setSmsMailingsEnable(prevState => !prevState)
                                                handleUpdateSettings('sms')
                                            }
                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                </>
                                :
                                <>
                                    <p>Еще не назначено</p>
                                    <button
                                        onClick={() => {
                                            handleUpdateSettings('sms')
                                            setSmsMailingsAllowed(true);
                                            setSmsMailingsEnable(true)
                                        }}
                                    >
                                        Назначить
                                    </button>
                                </>
                            }
                        </div>
                    </div>


                    {/*Email-рассылки*/}
                    <div className={`list-item is-open`} style={{padding: 0}}>

                        <div className={'list-item-wrapper'}>
                            <div className={'list-item-center'}>
                                <span>Email-рассылки</span>
                            </div>

                        </div>

                        <div className={'list-item-bottom-hide-block settings'} style={{display: "flex", justifyContent: "space-between", alignItems:'center'}}>

                            {emailMailingsAllowed ?
                                <>
                                    <p>Даю согласие на Email-рассылки</p>
                                    <Switch
                                        color={'success'}
                                        checked={emailMailingsEnable}
                                        onChange={(e) => {
                                            if (emailMailingsEnable) {
                                                setIsOpen(true)
                                                setModalType('email')
                                            } else {
                                                setEmailMailingsEnable(prevState => !prevState);
                                                handleUpdateSettings('email');

                                            }
                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                </>
                                :
                                <>
                                    <p>Еще не назначено</p>
                                    <button
                                        onClick={() => {
                                            handleUpdateSettings('email')
                                            setEmailMailingsAllowed(true)
                                            setEmailMailingsEnable(true)
                                        }}
                                    >
                                        Назначить
                                    </button>
                                </>
                            }
                        </div>
                    </div>

                    {/*Мессенджер-рассылки*/}
                    <div className={`list-item is-open`} style={{padding: 0}}>

                        <div className={'list-item-wrapper'}>
                            <div className={'list-item-center'}>
                                <span>Мессенджер-рассылки</span>
                            </div>

                        </div>

                        <div className={'list-item-bottom-hide-block settings'} style={{display: "flex", justifyContent: "space-between", alignItems:'center'}}>

                            {messengerMailingsAllowed ?
                                <>
                                    <p>Даю согласие на мессенджер-рассылки</p>
                                    <Switch
                                        color={'success'}
                                        checked={messengerMailingsEnable}
                                        onChange={(e) => {

                                            if (messengerMailingsEnable) {
                                                setIsOpen(true)
                                                setModalType('messenger')
                                            } else {
                                                setMessengerMailingsEnable(prevState => !prevState)
                                                handleUpdateSettings('messenger');

                                            }

                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                </>
                                :
                                <>
                                    <p>Еще не назначено</p>
                                    <button
                                        onClick={() => {
                                            handleUpdateSettings('messenger')
                                            setMessengerMailingsAllowed(true);
                                            setMessengerMailingsEnable(true)
                                        }}
                                    >
                                        Назначить
                                    </button>
                                </>
                            }
                        </div>
                    </div>


                </div>


                <FeedbackForm title={'Насколько удобен личный кабинет?'} pageName={'Настройки'}/>
            </div>


            <div className={'bottom-btns-wrapper'}>
                <button
                    style={{flex:1}}
                    onClick={() => {
                        // navigate(-1)
                        navigate('/main');

                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>

            </div>


            <SettingsBottomSheet
                isOpen={isOpen}
                setOpen={setIsOpen}
                modalType={modalType}

                smsMailingsEnable={smsMailingsEnable}
                phoneCallEnable={phoneCallEnable}
                emailMailingsEnable={emailMailingsEnable}
                messengerMailingsEnable={messengerMailingsEnable}

                setPhoneCallEnable={setPhoneCallEnable}
                setSmsMailingsEnable={setSmsMailingsEnable}
                setEmailMailingsEnable={setEmailMailingsEnable}
                setMessengerMailingsEnable={setMessengerMailingsEnable}


                setPhoneCallAllowed={setPhoneCallAllowed}
                setSmsMailingsAllowed={setSmsMailingsAllowed}
                setEmailMailingsAllowed={setEmailMailingsAllowed}
                setMessengerMailingsAllowed={setMessengerMailingsAllowed}
                fetchUpdateUserSettings={fetchUpdateUserSettings}

            />



        </div>
    );
}

export default Settings;
