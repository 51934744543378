import apiClient from '../apiClient';

// Функция для получения авто
export const getUserCars = async () => {
    try {
        const response = await apiClient.get('/user/auto', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};

// Функция для удаления автомобиля по carRn
export const deleteUserCar = async (carRn) => {
    try {
        const response = await apiClient.delete('/user/car/delete', {
            data: { carRn }
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting user car:', error);
        throw error;
    }
};

