import React, {useEffect, useState} from 'react';

import '../../assets/css/style.css';
import { SuccessIcon} from "../../assets/svg/icons";
import {Link} from "react-router-dom"; // Assuming you have a CSS file for styles


function SuccessModal({closeModal}) {

    return (
        <div className=" success-modal" style={{padding: 20}}>

            <div className={'success-modal-container'}>

                <SuccessIcon/>

                <h1 style={{fontSize:18, fontWeight: '600', marginBottom:12, marginTop:24}}>Успешно</h1>
            </div>

            {/*<div className="bottom-btns-wrapper" style={{padding: 0, position: 'absolute', bottom: 20, width: 'calc(100% - 40px)'}}>*/}
            {/*    <button onClick={closeModal} className={'red-btn'}>Хорошо</button>*/}
            {/*</div>*/}


        </div>
    )
}

export default SuccessModal;
