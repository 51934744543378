import { useState, useEffect } from 'react';
import {deleteUserCar, getUserCars} from '../utils/api/myCarsApi'; // Импорт функции получения профиля

const useMyCars = () => {
    const [myCarsAnswer, setMyCarsAnswer] = useState(null); // Состояние для хранения профиля
    const [loadingMyCars, setLoadingMyCars] = useState(false); // Состояние для индикации загрузки
    const [errorMyCars, setErrorMyCars] = useState(null); // Состояние для хранения ошибок

    // Функция для получения профиля
    const fetchUserCars = async () => {
        setLoadingMyCars(true);
        setErrorMyCars(null);
        setMyCarsAnswer(null);
        try {
            const data = await getUserCars(); // Получение профиля через API
            setMyCarsAnswer(data); // Сохранение профиля в состоянии
        } catch (err) {
            setErrorMyCars(err); // Сохранение ошибки в состоянии
        } finally {
            setLoadingMyCars(false); // Сброс состояния загрузки
        }
    };

    const [myCarsDeleteAnswer, setMyCarsDeleteAnswer] = useState(null);
    const [myCarsDeleteLoading, setMyCarsDeleteLoading] = useState(false);
    const [myCarsDeleteError, setMyCarsDeleteError] = useState(null);

    // Функция для получения профиля
    const fetchDeleteUserCars = async (carRn) => {
        setMyCarsDeleteLoading(true);
        setMyCarsDeleteError(null);
        setMyCarsDeleteAnswer(null);
        try {
            const data = await deleteUserCar(carRn); // Получение профиля через API
            setMyCarsDeleteAnswer(data); // Сохранение профиля в состоянии
        } catch (err) {
            setMyCarsDeleteError(err); // Сохранение ошибки в состоянии
        } finally {
            setMyCarsDeleteLoading(false); // Сброс состояния загрузки
        }
    };
    //
    // useEffect(() => {
    //     fetchUserCars();
    // }, []);

    return {
        myCarsAnswer,
        loadingMyCars,
        errorMyCars,
        fetchUserCars ,

        myCarsDeleteAnswer,
        myCarsDeleteLoading,
        myCarsDeleteError,
        fetchDeleteUserCars
    };
};

export default useMyCars;
