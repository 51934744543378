import apiClient from '../apiClient';

// Функция для получения профиля пользователя
export const updateUserSettingsApi = async (payload) => {
    try {
        const response = await apiClient.post('/user/profile/consents/update', payload);
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};

export const getUserSettingsApi = async () => {
    try {
        const response = await apiClient.get('/user/profile/consents');
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};
