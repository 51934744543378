// import React, { useEffect, useState, useMemo } from 'react';
// import { useTelegram } from '../../TelegramContext';
// import { Link, useNavigate } from 'react-router-dom';
// import '../../assets/css/style.css'; // Assuming you have a CSS file for styles
// import { BACKEND_URL } from '../../utils/constants';
//
// import Loader from "../../components/Loader";
// import useTranslation from "../../hooks/useTranslation";
// import Header from "../../components/Header";
// import useUserProfile from "../../hooks/useUserProfile";
// import {BottomArrowIcon, LogoIcon, PlusIcon, ZamokIcon} from "../../assets/svg/icons";
// import infoImage from "../../assets/img/main/info.png";
//
// import DataNotEditableBottomSheet from "./include/DataNotEditableBottomSheet";
// import ErrorModal from "../../components/ErrorModal";
// import SuccessModal from "../../components/SuccessModal";
// import SaveDataAfterExitBottomSheet from "./include/SaveDataAfterExitBottomSheet";
//
// function Profile() {
//     const { user, triggerVibration, webApp } = useTelegram();
//     const navigate = useNavigate();
//     const { translation } = useTranslation();
//     const {
//         profileAnswer, loadingProfile, errorProfile, fetchUserProfile,
//         profileUpdateAnswer, profileUpdateLoading, profileUpdateError, fetchUpdateUserProfile
//     } = useUserProfile();
//
//     const [firstName, setFirstName] = useState('');
//     const [surName, setSurName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [phone, setPhone] = useState('');
//     const [email, setEmail] = useState('');
//
//     const [isOpen, setIsOpen] = useState(false);
//     const [showErrorModal, setShowErrorModal] = React.useState(false);
//     const [showSuccessModal, setShowSuccessModal] = React.useState(false);
//
//     useEffect(() => {
//         fetchUserProfile();
//     }, []);
//
//     useEffect(() => {
//         if (errorProfile) {
//             console.log(errorProfile, 'errorProfile');
//         }
//     }, [errorProfile]);
//
//     useEffect(() => {
//         if (profileUpdateAnswer) {
//             if (profileUpdateAnswer?.success) {
//                 setShowSuccessModal(true)
//                 setTimeout(()=>{
//                     setShowSuccessModal(false)
//                 }, 2000)
//             }
//             console.log(profileUpdateAnswer, 'profileUpdateAnswer');
//         }
//     }, [profileUpdateAnswer]);
//
//     useEffect(() => {
//         if (profileUpdateError) {
//             console.log(profileUpdateError, 'profileUpdateError');
//             setShowErrorModal(true);
//         }
//     }, [profileUpdateError]);
//
//     useEffect(() => {
//         if (profileAnswer) {
//             console.log(profileAnswer?.data, 'profileAnswer');
//
//             setFirstName(profileAnswer?.data?.firstName || '');
//             setSurName(profileAnswer?.data?.surName || '');
//             setLastName(profileAnswer?.data?.lastName || '');
//             setPhone(profileAnswer?.data?.phone || '');
//             setEmail(profileAnswer?.data?.email || '');
//         }
//     }, [profileAnswer]);
//
//     // Проверка на изменения значений
//     const isModified = useMemo(() => {
//         return (
//             firstName !== profileAnswer?.data?.firstName ||
//             surName !== profileAnswer?.data?.surName ||
//             lastName !== profileAnswer?.data?.lastName ||
//             // phone !== profileAnswer?.data?.phone ||
//             email !== profileAnswer?.data?.email
//         );
//     }, [firstName, surName, lastName, email, profileAnswer]);
//
//
//     if (showErrorModal) {
//         return <ErrorModal showBtn={true}  closeModal={()=> setShowErrorModal(false)}/>
//     }
//
//
//     return (
//         <div className="wrapper wrapper-flex" style={{ padding: 0 }}>
//             <Header />
//
//             <div className="top-block" style={{ backgroundImage: `url(${infoImage})`, borderRadius: 0 }}>
//                 <Link to={'/main'} className="top-block-logo">
//                     <LogoIcon />
//                 </Link>
//             </div>
//
//             <div
//                 className={'padding-block'}
//                 style={{
//                     flex: 1,
//                     borderRadius: "10px 10px 0 0",
//                     position: 'relative',
//                     top: -10,
//                     background: "white"
//                 }}
//             >
//                 <div className={'top-title-wrapper'}>
//                     <div className={'top-title-text'} style={{ marginLeft: 0 }}>
//                         Мои данные
//                     </div>
//                 </div>
//
//                 <div className={'list-wrapper'}>
//                     <div className="profile-input-wrapper">
//                         <input
//                             placeholder="Имя"
//                             value={firstName}
//                             type="text"
//                             onChange={(e) => setFirstName(e.target.value)}
//                             className="input"
//                         />
//                     </div>
//
//                     <div className="profile-input-wrapper">
//                         <input
//                             placeholder="Фамилия"
//                             value={surName}
//                             type="text"
//                             onChange={(e) => setSurName(e.target.value)}
//                             className="input"
//                         />
//                     </div>
//
//                     <div className="profile-input-wrapper">
//                         <input
//                             placeholder="Отчество"
//                             value={lastName}
//                             type="text"
//                             onChange={(e) => setLastName(e.target.value)}
//                             className="input"
//                         />
//                     </div>
//
//                     <div className="profile-input-wrapper">
//                         <input
//                             placeholder="Email"
//                             value={email}
//                             type="text"
//                             onChange={(e) => setEmail(e.target.value)}
//                             className="input"
//                         />
//                     </div>
//
//                     <div className="profile-input-wrapper"  style={{ cursor: 'pointer' }}>
//                         <div
//                             onClick={() => setIsOpen(true)}
//                             disabled={true}
//                             className="input"
//                         >
//                             {phone}
//                         </div>
//                        <div className={'profile-input-wrapper-block-icon'}>
//                            <ZamokIcon/>
//                        </div>
//                     </div>
//                 </div>
//             </div>
//
//             <div className={'bottom-btns-wrapper'} style={{ display: 'flex' }}>
//                 <button
//                     style={{ flex: 1 }}
//                     onClick={() => {
//                         // navigate(-1);
//                         navigate('/main')
//                     }}
//                     className={'simple-btn'}
//                 >
//                     Назад
//                 </button>
//
//                 <button
//                     style={{ flex: 1, opacity: !isModified ? 0.5 : 1 }}
//                     onClick={() => {
//                         fetchUpdateUserProfile({
//                             firstName: firstName,
//                             surName: surName,
//                             lastName: lastName,
//                             // phone: phone,
//                             email: email
//                         });
//                     }}
//                     className={'red-btn'}
//                     disabled={!isModified} // Блокировка кнопки, если нет изменений
//                 >
//                     Сохранить
//                 </button>
//             </div>
//
//             <DataNotEditableBottomSheet isOpen={isOpen} setOpen={setIsOpen} />
//
//             {/*<SaveDataAfterExitBottomSheet isOpen={isOpenSaveData} setOpen={setIsOpenSaveData} />*/}
//
//             {showSuccessModal &&
//                 <SuccessModal  closeModal={()=> setShowSuccessModal(false)}/>
//             }
//         </div>
//     );
// }
//
// export default Profile;

//
// import React, { useEffect, useState, useMemo } from 'react';
// import { useTelegram } from '../../TelegramContext';
// import { Link, useNavigate } from 'react-router-dom';
// import '../../assets/css/style.css';
//
// import Header from "../../components/Header";
// import useUserProfile from "../../hooks/useUserProfile";
// import ErrorModal from "../../components/ErrorModal";
// import SuccessModal from "../../components/SuccessModal";
// import SaveDataAfterExitBottomSheet from "./include/SaveDataAfterExitBottomSheet";
// import DataNotEditableBottomSheet from "./include/DataNotEditableBottomSheet";
//
// import { BottomArrowIcon, LogoIcon, ZamokIcon } from "../../assets/svg/icons";
// import infoImage from "../../assets/img/main/info.png";
//
// function Profile() {
//     const { user, triggerVibration, webApp } = useTelegram();
//     const navigate = useNavigate();
//     const {
//         profileAnswer, loadingProfile, errorProfile, fetchUserProfile,
//         profileUpdateAnswer, profileUpdateLoading, profileUpdateError, fetchUpdateUserProfile
//     } = useUserProfile();
//
//     const [firstName, setFirstName] = useState('');
//     const [surName, setSurName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [phone, setPhone] = useState('');
//     const [email, setEmail] = useState('');
//
//     const [isOpen, setIsOpen] = useState(false);
//     const [showErrorModal, setShowErrorModal] = useState(false);
//     const [showSuccessModal, setShowSuccessModal] = useState(false);
//     const [isOpenSaveData, setIsOpenSaveData] = useState(false);
//     const [pendingNavigation, setPendingNavigation] = useState(null); // Храним страницу, на которую хочет уйти пользователь
//
//     useEffect(() => {
//         fetchUserProfile();
//     }, []);
//
//     useEffect(() => {
//         if (profileAnswer) {
//             setFirstName(profileAnswer?.data?.firstName || '');
//             setSurName(profileAnswer?.data?.surName || '');
//             setLastName(profileAnswer?.data?.lastName || '');
//             setPhone(profileAnswer?.data?.phone || '');
//             setEmail(profileAnswer?.data?.email || '');
//         }
//     }, [profileAnswer]);
//
//     useEffect(() => {
//         if (profileUpdateAnswer?.success) {
//             setShowSuccessModal(true);
//             setTimeout(() => setShowSuccessModal(false), 2000);
//         }
//     }, [profileUpdateAnswer]);
//
//     useEffect(() => {
//         if (profileUpdateError) {
//             setShowErrorModal(true);
//         }
//     }, [profileUpdateError]);
//
//     // Проверка на изменения данных
//     const isModified = useMemo(() => {
//         return (
//             firstName !== profileAnswer?.data?.firstName ||
//             surName !== profileAnswer?.data?.surName ||
//             lastName !== profileAnswer?.data?.lastName ||
//             email !== profileAnswer?.data?.email
//         );
//     }, [firstName, surName, lastName, email, profileAnswer]);
//
//     // Попытка выхода: если есть изменения, открываем модалку
//     const handleExitAttempt = (path) => {
//         if (isModified) {
//             setPendingNavigation(path); // Сохраняем страницу
//             setIsOpenSaveData(true); // Открываем подтверждение выхода
//         } else {
//             navigate(path);
//         }
//     };
//
//     // Сохранение перед выходом
//     const handleSaveAndExit = () => {
//         fetchUpdateUserProfile({ firstName, surName, lastName, email });
//         setIsOpenSaveData(false);
//         if (pendingNavigation) {
//             navigate(pendingNavigation);
//         }
//     };
//
//
//     // ✅ Перехват кликов на ссылки, кнопки и кнопку "назад" в браузере
//     useEffect(() => {
//         const handleBeforeUnload = (event) => {
//             if (isModified) {
//                 event.preventDefault();
//                 event.returnValue = "Вы уверены, что хотите покинуть страницу? Данные не сохранены.";
//             }
//         };
//
//         const handlePopState = (event) => {
//             if (isModified) {
//                 event.preventDefault();
//                 setIsOpenSaveData(true);
//                 setPendingNavigation(() => () => navigate(-1)); // Сохранить переход назад
//                 window.history.pushState(null, "", window.location.pathname);
//             }
//         };
//
//         const handleClick = (event) => {
//             const target = event.target.closest("a, button");
//             if (target && target.tagName === "A") {
//                 event.preventDefault();
//                 if (isModified) {
//                     setIsOpenSaveData(true);
//                     setPendingNavigation(() => () => navigate(target.pathname));
//                 } else {
//                     navigate(target.pathname);
//                 }
//             }
//         };
//
//         window.addEventListener("beforeunload", handleBeforeUnload);
//         window.addEventListener("popstate", handlePopState);
//         document.addEventListener("click", handleClick);
//
//         return () => {
//             window.removeEventListener("beforeunload", handleBeforeUnload);
//             window.removeEventListener("popstate", handlePopState);
//             document.removeEventListener("click", handleClick);
//         };
//     }, [isModified, navigate]);
//
//
//     if (showErrorModal) {
//         return <ErrorModal showBtn={true} closeModal={() => setShowErrorModal(false)} />;
//     }
//
//     return (
//         <div className="wrapper wrapper-flex" style={{ padding: 0 }}>
//             <Header />
//
//             <div className="top-block" style={{ backgroundImage: `url(${infoImage})`, borderRadius: 0 }}>
//                 <a href={'/main'} className="top-block-logo">
//                     <LogoIcon />
//                 </a>
//             </div>
//
//             <div
//                 className="padding-block"
//                 style={{
//                     flex: 1,
//                     borderRadius: "10px 10px 0 0",
//                     position: 'relative',
//                     top: -10,
//                     background: "white"
//                 }}
//             >
//                 <div className="top-title-wrapper">
//                     <div className="top-title-text" style={{ marginLeft: 0 }}>
//                         Мои данные
//                     </div>
//                 </div>
//
//                 <div className="list-wrapper">
//                     <div className="profile-input-wrapper">
//                         <input placeholder="Имя" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="input" />
//                     </div>
//
//                     <div className="profile-input-wrapper">
//                         <input placeholder="Фамилия" value={surName} onChange={(e) => setSurName(e.target.value)} className="input" />
//                     </div>
//
//                     <div className="profile-input-wrapper">
//                         <input placeholder="Отчество" value={lastName} onChange={(e) => setLastName(e.target.value)} className="input" />
//                     </div>
//
//                     <div className="profile-input-wrapper">
//                         <input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="input" />
//                     </div>
//
//                     <div className="profile-input-wrapper" style={{ cursor: 'pointer' }}>
//                         <div onClick={() => setIsOpen(true)} className="input">
//                             {phone}
//                         </div>
//                         <div className="profile-input-wrapper-block-icon">
//                             <ZamokIcon />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//
//             <div className="bottom-btns-wrapper">
//                 <button className="simple-btn" onClick={() => handleExitAttempt('/main')}>
//                     Назад
//                 </button>
//
//                 <button
//                     className="red-btn"
//                     onClick={handleSaveAndExit}
//                     disabled={!isModified}
//                 >
//                     Сохранить
//                 </button>
//             </div>
//
//             {/* Модальное окно подтверждения выхода */}
//             <SaveDataAfterExitBottomSheet
//                 isOpen={isOpenSaveData}
//                 setOpen={setIsOpenSaveData}
//                 onSave={handleSaveAndExit}
//                 onExit={() => navigate(pendingNavigation)}
//             />
//
//             <DataNotEditableBottomSheet isOpen={isOpen} setOpen={setIsOpen} />
//
//             {showSuccessModal && <SuccessModal closeModal={() => setShowSuccessModal(false)} />}
//         </div>
//     );
// }
//
// export default Profile;




// import React, { useEffect, useState, useMemo, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
//
// import Header from "../../components/Header";
// import useUserProfile from "../../hooks/useUserProfile";
// import ErrorModal from "../../components/ErrorModal";
// import SuccessModal from "../../components/SuccessModal";
// import SaveDataAfterExitBottomSheet from "./include/SaveDataAfterExitBottomSheet";
// import DataNotEditableBottomSheet from "./include/DataNotEditableBottomSheet";
//
// import { LogoIcon, ZamokIcon } from "../../assets/svg/icons";
// import infoImage from "../../assets/img/main/info.png";
//
// function Profile() {
//     const navigate = useNavigate();
//     const {
//         profileAnswer, fetchUserProfile, loadingProfile,
//         profileUpdateAnswer, fetchUpdateUserProfile
//     } = useUserProfile();
//
//     const [firstName, setFirstName] = useState('');
//     const [surName, setSurName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [phone, setPhone] = useState('');
//     const [email, setEmail] = useState('');
//
//     const [isOpen, setIsOpen] = useState(false);
//     const [showErrorModal, setShowErrorModal] = useState(false);
//     const [showSuccessModal, setShowSuccessModal] = useState(false);
//     const [isOpenSaveData, setIsOpenSaveData] = useState(false);
//     const [pendingNavigation, setPendingNavigation] = useState(null);
//
//     // ✅ Флаг для предотвращения срабатывания при загрузке
//     const isInitialRender = useRef(true);
//
//     useEffect(() => {
//         fetchUserProfile();
//     }, []);
//
//     useEffect(() => {
//         if (profileAnswer) {
//             setFirstName(profileAnswer?.data?.firstName || '');
//             setSurName(profileAnswer?.data?.surName || '');
//             setLastName(profileAnswer?.data?.lastName || '');
//             setPhone(profileAnswer?.data?.phone || '');
//             setEmail(profileAnswer?.data?.email || '');
//         }
//     }, [profileAnswer]);
//
//     useEffect(() => {
//         if (profileUpdateAnswer?.success) {
//             setShowSuccessModal(true);
//             setTimeout(() => setShowSuccessModal(false), 2000);
//         }
//     }, [profileUpdateAnswer]);
//
//     // ✅ Проверка изменений только после загрузки профиля
//     const isModified = useMemo(() => {
//         if (loadingProfile) return false; // Не показываем модалку, пока грузится профиль
//         return (
//             firstName !== profileAnswer?.data?.firstName ||
//             surName !== profileAnswer?.data?.surName ||
//             lastName !== profileAnswer?.data?.lastName ||
//             email !== profileAnswer?.data?.email
//         );
//     }, [firstName, surName, lastName, email, profileAnswer, loadingProfile]);
//
//     // ✅ Попытка выхода
//     const handleExitAttempt = (path) => {
//         if (isModified) {
//             setPendingNavigation(path);
//             setIsOpenSaveData(true);
//         } else {
//             navigate(path);
//         }
//     };
//
//     // ✅ Сохранение перед выходом
//     const handleSaveAndExit = () => {
//         fetchUpdateUserProfile({ firstName, surName, lastName, email });
//         setIsOpenSaveData(false);
//         // if (pendingNavigation) {
//         //     navigate(pendingNavigation);
//         // }
//     };
//
//     // ✅ Перехват выхода (только после загрузки данных)
//     useEffect(() => {
//         if (loadingProfile || isInitialRender.current) return; // Отключаем обработку выхода при загрузке
//
//         const handleBeforeUnload = (event) => {
//             if (isModified) {
//                 event.preventDefault();
//                 event.returnValue = "Вы уверены, что хотите покинуть страницу? Данные не сохранены.";
//             }
//         };
//
//         const handlePopState = (event) => {
//             if (isModified) {
//                 event.preventDefault();
//                 setIsOpenSaveData(true);
//                 setPendingNavigation(() => () => navigate(-1));
//                 window.history.pushState(null, "", window.location.pathname);
//             }
//         };
//
//         const handleClick = (event) => {
//             const target = event.target.closest("a, button");
//             if (target && target.tagName === "A" && profileAnswer?.data?.phone) {
//                 event.preventDefault();
//                 if (isModified) {
//                     setIsOpenSaveData(true);
//                     setPendingNavigation(() => () => navigate(target.pathname));
//                 } else {
//                     navigate(target.pathname);
//                 }
//             }
//         };
//
//         window.addEventListener("beforeunload", handleBeforeUnload);
//         window.addEventListener("popstate", handlePopState);
//         document.addEventListener("click", handleClick);
//
//         return () => {
//             window.removeEventListener("beforeunload", handleBeforeUnload);
//             window.removeEventListener("popstate", handlePopState);
//             document.removeEventListener("click", handleClick);
//         };
//     }, [isModified, navigate, loadingProfile]);
//
//     // ✅ Включаем обработку выхода только после загрузки данных
//     useEffect(() => {
//         if (!loadingProfile) {
//             isInitialRender.current = false;
//         }
//     }, [loadingProfile]);
//
//     if (showErrorModal) {
//         return <ErrorModal showBtn={true} closeModal={() => setShowErrorModal(false)} />;
//     }
//
//     return (
//         <div className="wrapper wrapper-flex" style={{ padding: 0 }}>
//             <Header />
//
//             <div className="top-block" style={{ backgroundImage: `url(${infoImage})`, borderRadius: 0 }}>
//                 <a href={'/main'} className="top-block-logo">
//                     <LogoIcon />
//                 </a>
//             </div>
//
//             <div className="padding-block" style={{ flex: 1, borderRadius: "10px 10px 0 0", position: 'relative', top: -10, background: "white" }}>
//                 <div className="top-title-wrapper">
//                     <div className="top-title-text" style={{ marginLeft: 0 }}>
//                         Мои данные
//                     </div>
//                 </div>
//
//                 <div className="list-wrapper">
//                     <div className="profile-input-wrapper">
//                         <input placeholder="Имя" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="input" />
//                     </div>
//                     <div className="profile-input-wrapper">
//                         <input placeholder="Фамилия" value={surName} onChange={(e) => setSurName(e.target.value)} className="input" />
//                     </div>
//                     <div className="profile-input-wrapper">
//                         <input placeholder="Отчество" value={lastName} onChange={(e) => setLastName(e.target.value)} className="input" />
//                     </div>
//                     <div className="profile-input-wrapper">
//                         <input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="input" />
//                     </div>
//                     <div className="profile-input-wrapper" style={{ cursor: 'pointer' }}>
//                         <div onClick={() => setIsOpen(true)} className="input">{phone}</div>
//                         <div className="profile-input-wrapper-block-icon">
//                             <ZamokIcon />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//
//             <div className="bottom-btns-wrapper">
//                 <button className="simple-btn" onClick={() => handleExitAttempt('/main')}>Назад</button>
//                 <button className="red-btn" onClick={handleSaveAndExit} disabled={!isModified}>Сохранить</button>
//             </div>
//
//             {/* ✅ Диалог подтверждения выхода */}
//             <SaveDataAfterExitBottomSheet
//                 isOpen={isOpenSaveData}
//                 setOpen={setIsOpenSaveData}
//                 onSave={handleSaveAndExit}
//                 onExit={() => navigate(pendingNavigation)}
//             />
//
//             <DataNotEditableBottomSheet isOpen={isOpen} setOpen={setIsOpen} />
//
//             {showSuccessModal && <SuccessModal closeModal={() => setShowSuccessModal(false)} />}
//         </div>
//     );
// }
//
// export default Profile;



import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from "../../components/Header";
import useUserProfile from "../../hooks/useUserProfile";
import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import SaveDataAfterExitBottomSheet from "./include/SaveDataAfterExitBottomSheet";
import DataNotEditableBottomSheet from "./include/DataNotEditableBottomSheet";

import { LogoIcon, ZamokIcon } from "../../assets/svg/icons";
import infoImage from "../../assets/img/main/info.png";

function Profile() {
    const navigate = useNavigate();
    const {
        profileAnswer, fetchUserProfile, loadingProfile,
        profileUpdateAnswer, fetchUpdateUserProfile
    } = useUserProfile();

    const [firstName, setFirstName] = useState('');
    const [surName, setSurName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isOpenSaveData, setIsOpenSaveData] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);

    // ✅ Флаг для предотвращения срабатывания при загрузке
    const isInitialRender = useRef(true);

    useEffect(() => {
        fetchUserProfile();
    }, []);

    useEffect(() => {
        if (profileAnswer) {
            setFirstName(profileAnswer?.data?.firstName || '');
            setSurName(profileAnswer?.data?.surName || '');
            setLastName(profileAnswer?.data?.lastName || '');
            setPhone(profileAnswer?.data?.phone || '');
            setEmail(profileAnswer?.data?.email || '');
        }
    }, [profileAnswer]);

    useEffect(() => {
        if (profileUpdateAnswer?.success) {
            setShowSuccessModal(true);
            setTimeout(() => setShowSuccessModal(false), 2000);

            // ✅ Обновляем данные, чтобы isModified стал false
            fetchUserProfile();
        }
    }, [profileUpdateAnswer]);

    // ✅ Проверка изменений только после загрузки профиля
    const isModified = useMemo(() => {
        if (loadingProfile) return false; // Не показываем модалку, пока грузится профиль
        return (
            firstName !== profileAnswer?.data?.firstName ||
            surName !== profileAnswer?.data?.surName ||
            lastName !== profileAnswer?.data?.lastName ||
            email !== profileAnswer?.data?.email
        );
    }, [firstName, surName, lastName, email, profileAnswer, loadingProfile]);

    // ✅ Попытка выхода
    const handleExitAttempt = (path) => {
        if (isModified) {
            setPendingNavigation(path);
            setIsOpenSaveData(true);
        } else {
            navigate(path);
        }
    };

    // ✅ Сохранение перед выходом
    const handleSaveAndExit = () => {
        fetchUpdateUserProfile({ firstName, surName, lastName, email });
        setIsOpenSaveData(false);
    };

    // ✅ Перехват выхода (только после загрузки данных)
    useEffect(() => {
        if (loadingProfile || isInitialRender.current) return; // Отключаем обработку выхода при загрузке

        const handleBeforeUnload = (event) => {
            if (isModified) {
                event.preventDefault();
                event.returnValue = "Вы уверены, что хотите покинуть страницу? Данные не сохранены.";
            }
        };

        const handlePopState = (event) => {
            if (isModified) {
                event.preventDefault();
                setIsOpenSaveData(true);
                setPendingNavigation(() => () => navigate(-1));
                window.history.pushState(null, "", window.location.pathname);
            }
        };

        const handleClick = (event) => {
            const target = event.target.closest("a, button");
            if (target && target.tagName === "A" && profileAnswer?.data?.phone) {
                event.preventDefault();
                if (isModified) {
                    setIsOpenSaveData(true);
                    setPendingNavigation(() => () => navigate(target.pathname));
                } else {
                    navigate(target.pathname);
                }
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        window.addEventListener("popstate", handlePopState);
        document.addEventListener("click", handleClick);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            window.removeEventListener("popstate", handlePopState);
            document.removeEventListener("click", handleClick);
        };
    }, [isModified, navigate, loadingProfile]);

    // ✅ Включаем обработку выхода только после загрузки данных
    useEffect(() => {
        if (!loadingProfile) {
            isInitialRender.current = false;
        }
    }, [loadingProfile]);

    if (showErrorModal) {
        return <ErrorModal showBtn={true} closeModal={() => setShowErrorModal(false)} />;
    }

    return (
        <div className="wrapper wrapper-flex" style={{ padding: 0 }}>
            <Header autoImage={infoImage} />

            {/*<div className="top-block" style={{ backgroundImage: `url(${infoImage})`, borderRadius: 0 }}>*/}
            {/*    <a href={'/main'} className="top-block-logo">*/}
            {/*        <LogoIcon />*/}
            {/*    </a>*/}
            {/*</div>*/}

            <div className="padding-block" style={{ flex: 1, borderRadius: "10px 10px 0 0", position: 'relative', top: -10, background: "white" }}>
                <div className="top-title-wrapper">
                    <div className="top-title-text" style={{ marginLeft: 0 }}>
                        Мои данные
                    </div>
                </div>

                <div className="list-wrapper" style={{marginBottom: 100}}>
                    <div className="profile-input-wrapper">
                        <input placeholder="Имя" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="input" />
                    </div>
                    <div className="profile-input-wrapper">
                        <input placeholder="Фамилия" value={surName} onChange={(e) => setSurName(e.target.value)} className="input" />
                    </div>
                    <div className="profile-input-wrapper">
                        <input placeholder="Отчество" value={lastName} onChange={(e) => setLastName(e.target.value)} className="input" />
                    </div>
                    <div className="profile-input-wrapper">
                        <input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="input" />
                    </div>
                    <div className="profile-input-wrapper" style={{ cursor: 'pointer', opacity:0.8 }}>
                        <div onClick={() => setIsOpen(true)} className="input">{phone}</div>
                        <div className="profile-input-wrapper-block-icon">
                            <ZamokIcon />
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottom-btns-wrapper">
                <button style={{flex:1}} className="simple-btn" onClick={() => handleExitAttempt('/main')}>Назад</button>
                <button style={{opacity: !isModified ? 0.6 : 1}} className="red-btn" onClick={handleSaveAndExit} disabled={!isModified}>Сохранить</button>
            </div>

            {/* ✅ Диалог подтверждения выхода */}
            <SaveDataAfterExitBottomSheet
                isOpen={isOpenSaveData}
                setOpen={setIsOpenSaveData}
                onSave={handleSaveAndExit}
                onExit={() => navigate(pendingNavigation)}
            />

            <DataNotEditableBottomSheet isOpen={isOpen} setOpen={setIsOpen} />

            {showSuccessModal && <SuccessModal closeModal={() => setShowSuccessModal(false)} />}
        </div>
    );
}

export default Profile;
