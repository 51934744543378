import apiClient from '../apiClient';

// Функция для получения профиля пользователя
export const getMainBanner = async () => {
    try {
        // const response = await apiClient.post('/banner/get', {regionId: 1, code: "baneryi-na-glavnoy"});
        const response = await apiClient.post('/banner/get', {regionId: 1, code: "banner-miniapp"});
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};
