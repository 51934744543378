import apiClient from '../apiClient'; // Предполагаем, что у вас есть axios instance

// Функция для получения списка заказов пользователя
export const getUserOrders = async () => {
    try {
        const response = await apiClient.get('/user/orders'); // GET-запрос к API
        return response.data;
    } catch (error) {
        console.error('Error fetching user orders:', error.response?.data || error.message);
        throw error.response?.data || error;
    }
};

// Функция для получения списка заказов пользователя
export const getOrderCoordinatesApi = async (shopId) => {
    try {
        const response = await apiClient.get(`/shops/get-map-info?shopId=${shopId}`); // GET-запрос к API
        return response.data;
    } catch (error) {
        console.error('Error fetching user orders:', error.response?.data || error.message);
        throw error.response?.data || error;
    }
};

// Функция для получения списка заказов пользователя
export const cancelOrderApi = async (data) => {
    try {
        const response = await apiClient.post('/user/orders/cancel', data);
        return response.data;
    } catch (error) {
        console.error('Error fetching user orders:', error.response?.data || error.message);
        throw error.response?.data || error;
    }
};
