import apiClient from '../apiClient';

// Функция для отправки SMS кода
export const sendSMSCode = async (phone, captchaTokenGoogle) => {
    try {
        // const response = await apiClient.post('/user/validate-tlg', {
        // const response = await apiClient.post('/user/send-sms-tg', {
        const response = await apiClient.post('/user/send-sms', {
            phone,
            captchaTokenGoogle
        });
        return response.data;
    } catch (error) {
        console.error('Error send SMS:', error);
        throw error;
    }
};

// Функция для валидации SMS кода
export const validateSMS = async (phone, code, telegramId) => {
    try {
        // const response = await apiClient.post('/user/validate-tlg', {
        const response = await apiClient.post('/user/validate-sms', {
            phone,
            code,
            telegramId
        });
        return response.data;
    } catch (error) {
        console.error('Error validating SMS:', error);
        throw error;
    }
};
