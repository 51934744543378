import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import Loader from "../../../components/Loader";
import Header from "../../../components/Header";
import {AutoIcon, BottomArrowIcon, LogoIcon, RightArrowIcon, UserIcon} from "../../../assets/svg/icons";
import axios from 'axios';
import {useSingleData} from "../../../context/SingleContext";
import topImage from "../../../assets/img/regServices/baner.png";
import FeedbackForm from "../../../components/FeedbackForm";
import useUserProfile from "../../../hooks/useUserProfile";
const authorizationToken = process.env.REACT_APP_WIDGET_AUTHORIZATION_TOKEN;

function RegistrationsForServicesList() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const { selectedSingleData, setSelectedSingleData } = useSingleData(); // Достаем функцию для установки выбранного заказа
    const { profileAnswer, loadingProfile, errorProfile, fetchUserProfile} = useUserProfile();

    const navigate = useNavigate();

    const [dataToServices, setDataToServices] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noData, setNoData] = useState(false);


    useEffect(()=>{
        if (profileAnswer) {
            postDataToServicesAPI(profileAnswer?.data?.phone)
            console.log(profileAnswer?.data?.phone, 'profileAnswer?.data?.phone')

        }
    }, [profileAnswer])


    function getDayOfWeekInRussian(dateStr) {
        // Преобразуем строку в объект Date
        const dateObj = new Date(dateStr);

        // Массив названий дней недели на русском
        const daysOfWeek = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];

        // Получаем название дня недели
        return daysOfWeek[dateObj.getDay()];
    }



    function formatDateToRussian(dateStr) {
        // Преобразуем строку в объект Date
        const dateObj = new Date(dateStr);

        // Массив названий месяцев на русском
        const months = [
            "января", "февраля", "марта", "апреля", "мая", "июня",
            "июля", "августа", "сентября", "октября", "ноября", "декабря"
        ];

        // Получаем день, месяц и год
        const day = dateObj.getDate();
        const month = months[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        // Форматируем строку
        return `${day} ${month} ${year} г.`;
    }


    function formatPhoneNumber(phoneNumber) {
        // Удаляем все нецифровые символы
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Проверяем, что номер состоит из 11 цифр
        if (cleaned.length !== 11) {
            throw new Error('Номер телефона должен содержать 11 цифр');
        }

        // Форматируем номер
        const countryCode = cleaned.slice(0, 1); // Код страны
        const areaCode = cleaned.slice(1, 4); // Код города
        const firstPart = cleaned.slice(4, 7); // Первая часть номера
        const secondPart = cleaned.slice(7, 9); // Вторая часть номера
        const thirdPart = cleaned.slice(9, 11); // Третья часть номера

        return `+${countryCode}(${areaCode})${firstPart}-${secondPart}-${thirdPart}`;
    }

    const postDataToServicesAPI = async (phone) => {

            try {
                // const phone = localStorage.getItem('phone')
                if (phone) {
                    let phone_ = formatPhoneNumber(phone)
                    console.log(phone_, 'phone_')
                    const response = await axios.post(`${process.env.REACT_APP_SERVICES_WIDGET_API_URL}/user/services`, {phone: phone_}, {
                        headers: {
                            'Content-Type': 'application/json', // Установите правильный тип контента, если требуется
                            'Authorization': `Bearer ${authorizationToken}`, // Если нужен токен, добавьте его здесь
                        }
                    });

                    console.log('Response:', response.data);
                    const filteredOrders = response.data.filter(order => order.status != "Отменена");


                    // setDataToServices(response.data)
                    setDataToServices(filteredOrders)
                    // if (response.data.length > 0) {

                    if (filteredOrders.length > 0) {
                        setNoData(false);
                    } else  {
                        setNoData(true);
                    }
                }

                setLoading(false)
            } catch (error) {
                console.error('Error posting data:', error.response?.data || error.message);
                throw error.response?.data || error;
                setNoData(true)
            }

    };


    // useEffect(() => {
    //     postDataToServicesAPI()
    // }, []);


    if (loading) {
        return (
            <Loader/>
        )
    }


    return (

        <div className="wrapper wrapper-flex" style={{padding: 0}}>

            <Header autoImage={topImage}/>

            {/*{noData ?*/}
            {/*    <div className="top-block" style={{height: 70}}>*/}
            {/*        <Link to={'/'} className="top-block-logo">*/}
            {/*            <LogoIcon/>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*    :*/}

            {/*    <div className="top-block" style={{backgroundImage: `url(${topImage})`, borderRadius: 0}}>*/}
            {/*        <Link to={'/'} className="top-block-logo">*/}
            {/*            <LogoIcon/>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*}*/}

            {/*<div className="top-block" style={{backgroundImage: `url(${topImage})`, borderRadius: 0}}>*/}
            {/*    <Link to={'/main'} className="top-block-logo">*/}
            {/*        <LogoIcon/>*/}
            {/*    </Link>*/}
            {/*</div>*/}

            <div
                className={'padding-block'}
                style={{
                    flex: 1,
                    borderRadius: "10px 10px 0 0 ",
                    position: 'relative',
                    top: -25,
                    background: "white",
                    // overflow: "scroll"
                }}
            >
                <div className={'top-title-wrapper'}>

                    {noData ?
                        <div className={'top-title-text'} style={{marginLeft: 0}}>
                            Пока нет записей на услуги в Колесо.ру
                        </div>
                        :
                        <div className={'top-title-text'} style={{marginLeft: 0}}>
                            Записи на услуги
                        </div>
                    }

                </div>

                <div className={'list-wrapper'}  style={{marginBottom: 100}}>


                    {noData ?
                        <>
                            <img style={{width: '100%'}}
                                 src={require('../../../assets/img/regServices/empty_reg_services.png')} alt=""/>
                        </>
                        :
                        <>
                            {dataToServices && dataToServices.map((item, index) => {
                                console.log(item?.status)
                                if (item?.status == 'Отменена') {
                                    return null
                                }
                                return (
                                    <Link
                                        key={index}
                                        to={'/registrations-for-service-single'}
                                        onClick={() => {
                                            setSelectedSingleData(item)
                                        }}
                                        className={`list-item `}
                                        style={{padding: 0}}
                                    >

                                        <div className={'list-item-wrapper'}>
                                            <div className={'list-item-center'}>
                                                <span>Шиномонтаж</span>
                                            </div>
                                            <div className={'list-item-bottom'}>
                                                <span style={{fontSize: 13}}></span>
                                                <span
                                                    style={{marginLeft: 0}}>{formatDateToRussian(item?.date?.date)} {getDayOfWeekInRussian(item?.date?.date)} {item?.date?.time}</span>
                                            </div>

                                            <BottomArrowIcon className={'bottom-arrow'}
                                                             style={{transform: 'rotate(-90deg)'}}/>
                                        </div>

                                    </Link>
                                )
                            })}

                        </>
                    }

                </div>


                <FeedbackForm title={'Насколько удобен личный кабинет?'} pageName={'Записи на услуги'}/>

                <div style={{width: '100%', height: 100,}}>

                </div>

                <div style={{marginBottom: window.Telegram?.WebApp?.platform == 'ios' ?  140 : 30}}></div>
            </div>


            <div className={'bottom-btns-wrapper'}>
                {noData ?

                    <>
                        <button
                            style={{flex: 1, marginRight: 10}}
                            onClick={() => {
                                // navigate(-1)
                                navigate('/main')
                            }}
                            className={'simple-btn'}
                        >
                            Назад
                        </button>

                        <a
                            href={'https://koleso.ru/rec/?widgetOpened'}
                            style={{flex: 1, marginRight: 0}}
                            onClick={() => {

                            }}
                            className={'red-btn'}
                        >
                            Записаться
                        </a>
                    </>


                    :

                    <button
                        style={{flex: 1, marginRight: 0}}
                        onClick={() => {
                            // navigate(-1)
                            navigate('/main')
                        }}
                        className={'simple-btn'}
                    >
                        Назад
                    </button>

                }


            </div>


        </div>

    );
}

export default RegistrationsForServicesList;
