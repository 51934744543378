import React from 'react';
import {SupportIcon} from "../../assets/svg/icons";

export default function CloseAndSupportButton() {
    const handleClick = () => {
        // Проверяем, доступен ли Telegram.WebApp
        if (window.Telegram?.WebApp) {
            // 1) Открываем deep link

            window.Telegram.WebApp.openLink(`https://t.me/${process.env.REACT_APP_BOT_NAME}?start=support`);

            // 2) Через небольшую задержку закрываем WebApp
            setTimeout(() => {
                window.Telegram.WebApp.close();
            }, 500);
        }
    };

    return (
        <button className={'close-and-support-btn'} onClick={handleClick}>
            <div className={'close-and-support-btn-left'}>
                <SupportIcon/>
                <div className={'close-and-support-btn-left-text-wrapper'} style={{marginLeft: 10}}>
                    <div className={'close-and-support-btn-left-text1'}>Чат с оператором</div>
                    <div className={'close-and-support-btn-left-text2'}>На связи</div>
                </div>
            </div>
            <div>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 12L10 8L6 4" stroke="#85808F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </button>
    );
}
