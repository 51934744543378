import React, {useEffect, useState} from 'react';

import '../../assets/css/style.css';
import {LogoIcon, RedLogo, ErrorIcon} from "../../assets/svg/icons";
import {Link} from "react-router-dom"; // Assuming you have a CSS file for styles


function ErrorModal({showBtn = false, closeModal}) {

    return (
        <div className="wrapper wrapper-flex error-modal" style={{padding: 20}}>

            <div style={{width:'100%', flex:1, display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>

                <ErrorIcon/>

                <h1 style={{fontSize:18, fontWeight: '600', marginBottom:12, marginTop:32, textAlign:'center'}}>К сожалению сервис временно недоступен</h1>
                <p style={{color: "#383838", fontSize: 15, textAlign:'center'}}>Попробуйте позже или обратитесь в техподдержку.</p>
            </div>

            {showBtn &&
                <div className="bottom-btns-wrapper" style={{padding: 0, position: 'absolute', bottom: 20, width: 'calc(100% - 40px)'}}>
                    <button onClick={closeModal} className={'red-btn'}>Хорошо</button>
                </div>
            }


        </div>
    )
}

export default ErrorModal;
