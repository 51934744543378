import React, {useEffect, useRef, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";

import Loader from "../../../components/Loader";
import useTranslation from "../../../hooks/useTranslation";
import Header from "../../../components/Header";
import {useSingleData} from "../../../context/SingleContext";
import {LogoIcon} from "../../../assets/svg/icons";
import { Sheet } from 'react-modal-sheet';
import CancelOrderBottomSheet from "./include/CancelOrderBottomSheet";
import {useUserOrders} from "../../../hooks/useUserOrders";

function MyOrdersSingle() {

    const {
        orderCoordinates,
        orderCoordinatesLoading,
        orderCoordinatesError,
        fetchOrderCoordinates
    } = useUserOrders(); // Получаем выбранный заказ из контекста


    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();
    const { selectedSingleData, setSelectedSingleData } = useSingleData(); // Получаем выбранный заказ из контекста

    const [loader, setLoader] = useState(true);
    const [isOpen, setOpen] = useState(false);

    useEffect(()=>{
        if (selectedSingleData) {
            fetchOrderCoordinates(selectedSingleData?.shopId)
            setLoader(false);
        }
        console.log(selectedSingleData, 'selectedOrder')
    }, [selectedSingleData])


    useEffect(()=>{
        if (orderCoordinates) {
            console.log(orderCoordinates, 'orderCoordinates')
        }
    }, [orderCoordinates])

    const handleNavigation = () => {
        // const address = encodeURIComponent(selectedSingleData?.address);


        console.log(orderCoordinates, 'orderCoordinates')
        // const lat = orderCoordinates?.data?.xCoord
        // const lng = orderCoordinates?.data?.yCoord
        //
        // console.log(lat, lng, 'latlng');
        // window.open(`https://yandex.com/maps/org/koleso_ru/1190483383/?ll=${lat},${lng}&z=15`, '_blank');
        window.open(orderCoordinates?.data?.yandexMapUrl, '_blank');

    };

    if (loader) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            <div className="wrapper wrapper-flex">

                <Header/>

                <div
                    className={'list-wrapper'}
                    style={{
                        flex: 1,
                        paddingBottom: 100
                 }}
                >

                    <div className={'list-item'} style={{background: 'unset', padding: 0}}>
                        <div className={'list-item-center'} style={{flexDirection: "column", alignItems: 'flex-start'}}>
                            <div style={{display: "flex"}}>
                                <span style={{
                                    fontSize: 20,
                                    fontWeight: 700
                                }}>Заказ № {selectedSingleData?.orderNumber}</span>
                                {selectedSingleData?.cancellationProcess == 1 &&
                                    <p className={'cancelation-yellow-block'}>отменяем</p>
                                }
                            </div>

                            <span style={{paddingLeft: 0}}>от {selectedSingleData?.orderDate} г.</span>
                        </div>

                    </div>

                    {selectedSingleData?.products && selectedSingleData?.products.map((item, index) => {
                        if (item?.code) {
                            return (
                                <div
                                    onClick={()=>{
                                        let pageUrl_ = `https://koleso.ru${item?.code}`;
                                        console.log(pageUrl_)
                                        window.Telegram.WebApp.openLink(pageUrl_);
                                    }}
                                    key={index}
                                    className={'order-item'}
                                    style={{textDecoration:'none'}}
                                >
                                    <div className={'order-item-left'}>
                                        <img src={item?.image}/>
                                    </div>
                                    <div className={'order-item-right'} style={{color:'black'}}>
                                        <p className={'order-item-title'}>
                                            {item?.title}
                                        </p>
                                        <p className={'order-item-price'}>{item?.price} ₽/шт.</p>
                                        <div className={'order-item-bottom'}>
                                            <span>{item?.quantity} шт.</span>
                                            <span style={{fontSize: 16, fontWeight: '700'}}>{item?.price * item?.quantity} ₽</span>
                                        </div>
                                    </div>

                                </div>
                            )
                        } else {
                            return (
                                <div  key={index} className={'order-item'} style={{textDecoration:'none'}}>
                                    <div className={'order-item-left'}>
                                        <img src={item?.image}/>
                                    </div>
                                    <div className={'order-item-right'} style={{color:'black'}}>
                                        <p className={'order-item-title'}>
                                            {item?.title}
                                        </p>
                                        <p className={'order-item-price'}>{item?.price / item?.quantity} ₽/шт.</p>
                                        <div className={'order-item-bottom'}>
                                            <span>{item?.quantity} шт.</span>
                                            <span style={{fontSize: 16, fontWeight: '700'}}>{item?.price} ₽</span>
                                        </div>
                                    </div>

                                </div>
                            )
                        }

                    })}

                    {selectedSingleData?.deliveryType == 'Самовывоз' && !orderCoordinatesError && !orderCoordinatesLoading &&
                        <p
                            onClick={handleNavigation}
                            className={'build-a-route'} style={{
                            fontSize: 13,
                            color: "#4E2B96",
                            display: "inline-flex",
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>Построить маршрут</p>
                    }


                    <div style={{width: '100%',  marginTop: 20, marginBottom: 20, textAlign: 'right', fontSize: 18,  color: 'black' }}>
                        Итог: <span style={{fontWeight: '700',}}>{selectedSingleData?.totalSumOrder}</span>
                    </div>

                </div>


                <div className={'bottom-btns-wrapper'} >
                    <button
                        style={{flex: 1}}
                        onClick={() => {
                            navigate(-1);
                            // navigate('/main')
                        }}
                        className={'simple-btn'}
                    >
                        Назад
                    </button>
                    <button
                        style={selectedSingleData?.cancellationProcess == 1 ? {opacity: 0.5} : {}}
                        onClick={() => {
                            if (selectedSingleData?.cancellationProcess == 0) {
                                setOpen(true)
                            }
                        }}
                        className={'red-btn'}
                    >
                        Отменить заказ
                    </button>
                </div>


            </div>

            <CancelOrderBottomSheet
                isOpen={isOpen}
                setOpen={setOpen}
                selectedSingleData={selectedSingleData}
            />
        </>
    );
}

export default MyOrdersSingle;
