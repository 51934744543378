import {Sheet} from "react-modal-sheet";
import React, {useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";


const DataNotEditableBottomSheet = ({isOpen, setOpen, onSave, onExit}) => {
    const navigate = useNavigate();
    const ref = useRef();

    const [height, setHeight] = useState(190);

    const snapTo = (i) => ref.current?.snapTo(i);

    return (
        <Sheet
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            initialSnap={0}
            snapPoints={[height, 150, 350, 0]}
        >
            <Sheet.Container>

                <Sheet.Header />
                <Sheet.Content>

                    <div className={'cancel-order-bottomshet-wrapper'}>

                        <div className={'cancel-success-wrapper'}>
                            <div style={{color: '#000', marginBottom: 10, fontSize:17, fontWeight: 'bold'}}>
                                Сохранить изменения?
                            </div>

                            <div style={{color: 'rgba(0,0,0,0.7)', marginBottom: 25, fontSize:14, }}>
                                Есть несохраненные данные
                            </div>

                            <div className="bottom-btns-wrapper" style={{padding: 0}}>
                                <button
                                    style={{flex: 1}}
                                    className="simple-btn"
                                    onClick={onExit}
                                >
                                    Не сохранять
                                </button>

                                <button
                                    className="red-btn"
                                    onClick={onSave}
                                    // disabled={!isModified}
                                >
                                    Сохранить
                                </button>
                            </div>
                        </div>

                    </div>

                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop/>
        </Sheet>
    )
}

export default DataNotEditableBottomSheet
