import React, {useEffect, useState} from 'react';
import '../assets/css/style.css'
import {useTelegram} from "../TelegramContext";
import {BurgerMenu, LogoIcon} from "../assets/svg/icons";
import Menu from "./Menu";
import {Link} from "react-router-dom";
import autoImage from "../assets/img/main/auto.png";
// import autoImage from "../assets/img/main/auto.png";

function Header({autoImage = null, isMain = false}) {

    const { user, triggerVibration } = useTelegram();

    const [selectedLanguage, setSelectedLanguage] = useState('ru');


    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            // window.scrollY возвращает количество прокрученных пикселей от верха документа
            console.log(window.scrollY, 'window.scrollY')
            setScrollTop(window.scrollY);
        };

        // Вешаем слушатель события "scroll"
        window.addEventListener("scroll", handleScroll);

        // Убираем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header style={{top:0}} className={!autoImage ? `header-without-image` : ""}>

            <div className={`top-block ${isMain ? 'top-block-main' : ''}`} style={{backgroundImage: `url(${autoImage})`, borderRadius: 0}}>

                <div className={`top-block-top `} style={{background: scrollTop >= 30 ? 'white' : 'transparent', transition: '0.5s'}}>

                    <Link to={'/main'} className="top-block-logo">
                        <LogoIcon/>
                    </Link>

                    <div className={'burger-menu'}>
                        <Menu/>
                    </div>
                </div>

            </div>



        </header>
    );
}

export default Header;
