import apiClient from '../apiClient'; // Импорт вашего axios клиента

// Функция для получения списка марок автомобилей
export const getAutoMarks = async () => {
    try {
        const response = await apiClient.get('/catalog/get-auto-mark');
        return response.data;
    } catch (error) {
        console.error('Error fetching auto marks:', error);
        throw error;
    }
};

// Функция для получения списка моделей по марке
export const getAutoModels = async (markId) => {
    try {
        const response = await apiClient.get(`/catalog/v1/get-auto-model`, { params: { markId } });
        return response.data;
    } catch (error) {
        console.error('Error fetching auto models:', error);
        throw error;
    }
};

// Функция для получения списка годов по модели и марке
export const getAutoYears = async (groupCode, markId) => {
    try {
        // const response = await apiClient.get(`/catalog/get-auto-year`, { params: { groupCode, markId } });
        const response = await apiClient.get(`/catalog/v1/get-auto-year`, { params: { groupCode, markId } });
        return response.data;
    } catch (error) {
        console.error('Error fetching auto years:', error);
        throw error;
    }
};

export const getAutoModification = async (groupCode, markId, year) => {
    try {
        const response = await apiClient.get(`/catalog/v1/get-auto-modification`, { params: { groupCode, markId, year } });
        return response.data;
    } catch (error) {
        console.error('Error fetching auto years:', error);
        throw error;
    }
};

export const AddNewAutoApi = async (modId, plate, vin, year) => {
    try {
        // const response = await apiClient.post(`/user/car/add`,  {
        const response = await apiClient.post(`/user/car/add-v1`,  {
            modId,
            plate,
            vin,
            year
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching auto years:', error);
        throw error;
    }
};
