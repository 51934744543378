import apiClient from '../apiClient';

// Функция для получения профиля пользователя
export const feedbackApi = async (data) => {
    try {
        const response = await apiClient.post('/feedback', data);
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};
