import React, {useEffect, useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function MyComponent({label, value, onChange, error}) {

    useEffect(() => {
        if (error) {
            console.log(error, 'error')
        }
    }, [error]);

    return (
        <div className="org-create-order__form-group">

            <PhoneInput
                inputStyle={{width:'100%',}}
                country={'ru'}         // Отображает флаг РФ
                onlyCountries={['ru']} // Можно указать страны, которые хотите поддерживать
                value={value}
                onChange={(value) => onChange(value)}
                placeholder="Введите номер телефона"
            />

            {error && <div className="error-message">{error}</div>}

        </div>
    );
}

export default MyComponent;
