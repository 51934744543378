import {useEffect, useState} from 'react';
import {AddNewAutoApi, getAutoMarks, getAutoModels, getAutoModification, getAutoYears} from '../utils/api/filterApi';

export const useAutoData = () => {
    // Состояния для марок
    const [marksLoading, setMarksLoading] = useState(false);
    const [marksData, setMarksData] = useState(null);
    const [marksError, setMarksError] = useState(null);

    // Состояния для моделей
    const [modelsLoading, setModelsLoading] = useState(false);
    const [modelsData, setModelsData] = useState(null);
    const [modelsError, setModelsError] = useState(null);

    // Состояния для модификаций
    const [modificationLoading, setModificationLoading] = useState(false);
    const [modificationData, setModificationData] = useState(null);
    const [modificationError, setModificationError] = useState(null);


    // Состояния для годов
    const [yearsLoading, setYearsLoading] = useState(false);
    const [yearsData, setYearsData] = useState(null);
    const [yearsError, setYearsError] = useState(null);

    const [createAutoLoading, setCreateAutoLoading] = useState(false);
    const [createAutoData, setCreateAutoData] = useState(null);
    const [createAutoError, setCreateAutoError] = useState(null);


    const formatCarMarks = (data) => {
        return data?.map(item => ({
            label: item?.Mark, // Используем название марки для поля label
            value: item?.Id // Используем ID для поля value
        }));
    };

    const formatCarModels = (data) => {
        console.log(data, 'formatCarModels')
        return data?.map(item => ({
            label: item?.name, // Используем название марки для поля label
            value: item?.groupCode, // Используем ID для поля value
            extId: item?.extId
        }));
    };

    const formatModification = (data) => {
        return data?.map(item => ({
            label: item?.name, // Используем название марки для поля label
            value: item?.code, // Используем ID для поля value
            id: item?.id, // Используем ID для поля value
        }));
    };

    const formatYearsData = (data) => {
        return Object.keys(data)?.map(year => ({
            label: data[year], // Год как label
            value: year[year] // Первое значение в массиве (ID) как value
        }));
    };

    useEffect(() => {
        const fetchAutoMarks = async () => {
            setMarksLoading(true);
            setModelsData(null);
            setYearsData(null)
            try {
                const result = await getAutoMarks();
                console.log(result?.data, '---')
                let marks_ = formatCarMarks(result?.data)
                setMarksData(marks_);
            } catch (error) {
                setMarksError(error);
            } finally {
                setMarksLoading(false);
            }
        };

        fetchAutoMarks();
    }, []);
    // Функция для получения марок


    // Функция для получения моделей по markId
    const fetchAutoModels = async (markId) => {
        if (!markId) return;
        setModelsLoading(true);
        setYearsData(null)

        try {
            const result = await getAutoModels(markId);
            let models_ = formatCarModels(result.data)
            setModelsData(models_);
        } catch (error) {
            setModelsError(error);
        } finally {
            setModelsLoading(false);
        }
    };

    // Функция для получения годов по groupCode и markId
    const fetchAutoYears = async (groupCode, markId) => {
        if (!groupCode || !markId) return;
        setYearsLoading(true);
        try {
            const result = await getAutoYears(groupCode, markId);
            let years_ = formatYearsData(result.data)
            console.log(years_, 'years_')
            setYearsData(years_);
        } catch (error) {
            setYearsError(error);
        } finally {
            setYearsLoading(false);
        }
    };





    // Функция для получения годов по groupCode и markId
    const fetchAutoModification = async (groupCode, markId, year) => {
        if (!groupCode || !markId|| !year) return;
        setModificationLoading(true);

        console.log('fetchAutoModification', groupCode, markId, year)
        try {
            const result = await getAutoModification(groupCode, markId, year);
            let modification_ = formatModification(result.data)
            console.log(result.data, 'fetchAutoModification')
            setModificationData(modification_);
        } catch (error) {
            setModificationError(error);
        } finally {
            setModificationLoading(false);
        }
    };





    const fetchCreateAuto = async (modId, plate, vin, year) => {
        if (!modId ) return;
        setCreateAutoLoading(true);

        console.log('fetchCreateAuto',modId, plate, vin, year);

        try {
            const result = await AddNewAutoApi(modId, plate, vin, year);
            console.log(result.data, 'fetchAutoModification')
            setCreateAutoData(result.data);
        } catch (error) {
            setCreateAutoError(error);
        } finally {
            setCreateAutoLoading(false);
        }
    };

    return {
        marksLoading,
        marksData,
        marksError,

        modelsLoading,
        modelsData,
        modelsError,
        fetchAutoModels,

        yearsLoading,
        yearsData,
        yearsError,
        fetchAutoYears,

        modificationLoading,
        modificationData,
        modificationError,
        fetchAutoModification,

        createAutoLoading,
        createAutoData,
        createAutoError,
        fetchCreateAuto,
    };
};
