import { useState, useEffect } from 'react';
import {getMainBanner} from "../utils/api/mainBanerApi"; // Импорт функции получения профиля

const useMainBanner = () => {
    const [mainBannerAnswer, setMainBannerAnswer] = useState(null); // Состояние для хранения профиля
    const [mainBannerLoading, setMainBannerLoading] = useState(false); // Состояние для индикации загрузки
    const [mainBannerError, setMainBannerError] = useState(null); // Состояние для хранения ошибок

    // Функция для получения профиля
    const fetchMainBanner = async (data) => {

        setMainBannerLoading(true);
        setMainBannerError(null);
        setMainBannerAnswer(null);

        // const phone = localStorage.getItem('phone');

        try {
            const response = await getMainBanner(data);
            setMainBannerAnswer(response);
        } catch (err) {
            setMainBannerError(err);
        } finally {
            setMainBannerLoading(false);
        }
    };

    return {
        mainBannerAnswer,
        mainBannerLoading,
        mainBannerError,
        fetchMainBanner
    };
};

export default useMainBanner;
