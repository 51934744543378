import React, { useState, useEffect } from 'react';
import '../../assets/css/style.css';
import PhoneNumberInput from "../../components/PhoneNumberInput";
import { sendSMSCode, validateSMS } from '../../utils/api/authApi';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const PhoneVerificationForm = ({onLogin}) => {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const [smsCode, setSmsCode] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [telegramId, setTelegramId] = useState(window.Telegram?.WebApp?.initDataUnsafe?.user?.id || null);
    const [timer, setTimer] = useState(0);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        if (window.Telegram?.WebApp?.initDataUnsafe?.user) {
            setTelegramId(window.Telegram?.WebApp?.initDataUnsafe?.user?.id)
        } else {
            setTelegramId(null)
        }
    }, [window.Telegram?.WebApp]);


    useEffect(() => {


        const savedTime = localStorage.getItem('smsTimerStart');
        if (savedTime) {
            const elapsed = Math.floor((Date.now() - parseInt(savedTime, 10)) / 1000);
            if (elapsed < 60) {
                setTimer(60 - elapsed);
            } else {
                localStorage.removeItem('smsTimerStart');
            }
        }
    }, []);

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else {
            clearInterval(interval);
            localStorage.removeItem('smsTimerStart');
        }
        return () => clearInterval(interval);
    }, [timer]);

    const handleSendSMS = async (e) => {
        e.preventDefault();

        if (!executeRecaptcha) {
            console.error("Recaptcha not yet available");
            return;
        }

        if (!isChecked) {
            alert('Пожалуйста, дайте согласие на обработку персональных данных.');
            return;
        }

        try {
            const captchaTokenGoogle = await executeRecaptcha("submit");
            console.log('Captcha token:', captchaTokenGoogle);

            await sendSMSCode(phoneNumber, captchaTokenGoogle);
            setIsCodeSent(true);
            setTimer(60);
            localStorage.setItem('smsTimerStart', Date.now().toString());
            // alert('Код отправлен!');
        } catch (error) {
            console.error('Ошибка отправки SMS:', error);
            alert('Ошибка отправки SMS. Попробуйте снова.');
        }
    };

    const handleValidateSMS = async (e) => {
        e.preventDefault();

        try {
            const response = await validateSMS(phoneNumber, smsCode, telegramId);

            const {token, refresh_token} = response.data;

            localStorage.setItem('token', token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('phone', phoneNumber);

            onLogin(window.location?.pathname);
            // alert('SMS успешно подтвержден!');
            console.log('Ответ сервера:', response);
        } catch (error) {
            console.error('Ошибка валидации SMS:', error);
            alert('Ошибка валидации кода. Попробуйте снова.');
        }
    };

    const handlePhoneChange = (phone) => {
        setPhoneNumber(phone);
    };

    const handleReset = () => {
        setIsCodeSent(false);
        setSmsCode('');
        setPhoneNumber('');
    };

    const handleResendCode = async () => {
        try {
            const captchaTokenGoogle = await executeRecaptcha("submit");
            console.log('Captcha token for resend:', captchaTokenGoogle);

            await sendSMSCode(phoneNumber, captchaTokenGoogle);
            setTimer(60);
            localStorage.setItem('smsTimerStart', Date.now().toString());
            alert('Код отправлен повторно!');
        } catch (error) {
            console.error('Ошибка при повторной отправке SMS:', error);
            alert('Ошибка при повторной отправке. Попробуйте снова.');
        }
    };

    return (
        <div className="login_form-container">
            <h1 className="login_form-title">Войти</h1>
            <p className="login_form-subtitle">
                Мы отправим на номер SMS-сообщение с кодом подтверждения.
            </p>

            {!isCodeSent ? (
                <form
                    className="login_phone-verification-form"
                    onSubmit={handleSendSMS}
                >
                    <label htmlFor="phone" className="login_form-label">
                        Контактный телефон
                    </label>
                    <div style={{ marginBottom: 20, marginTop: 10 }}>
                        <PhoneNumberInput
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                        />
                    </div>
                    {/*<div className="login_checkbox-container">*/}
                    {/*    <input*/}
                    {/*        id="consent"*/}
                    {/*        type="checkbox"*/}
                    {/*        className="login_form-checkbox"*/}
                    {/*        checked={isChecked}*/}
                    {/*        onChange={() => setIsChecked(!isChecked)}*/}
                    {/*    />*/}
                    {/*    <label htmlFor="consent" className="login_form-checkbox-label">*/}
                    {/*        Даю согласие на обработку{' '}*/}
                    {/*        <a*/}
                    {/*            href="https://koleso.ru/static-page/soglasie/"*/}
                    {/*            target="_blank"*/}
                    {/*            rel="noopener noreferrer"*/}
                    {/*            className="login_personal-data-link"*/}
                    {/*        >*/}
                    {/*            персональных данных*/}
                    {/*        </a>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <div className="login_checkbox-container">
                        <input
                            id="consent"
                            type="checkbox"
                            className="login_form-checkbox"
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                        />
                        <label htmlFor="consent" className="login_form-checkbox-label">
                            Даю согласие на обработку{' '}
                            <a
                                href="https://koleso.ru/static-page/soglasie/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="login_personal-data-link"
                            >
                                персональных данных
                            </a>
                        </label>
                    </div>


                    {timer > 0 && (
                        <p className="login_timer">
                            Вы можете запросить код повторно через {timer} секунд.
                        </p>
                    )}
                    <button
                        type="submit"
                        className="login_submit-button"
                        disabled={timer > 0}
                    >
                        Получить код
                    </button>
                </form>
            ) : (
                <form
                    className="login_phone-verification-form"
                    onSubmit={handleValidateSMS}
                >
                    <label htmlFor="sms-code" className="login_form-label">
                        Введите код из SMS
                    </label>
                    <input
                        id="sms-code"
                        type="text"
                        className="login_form-input"
                        placeholder="Введите код"
                        value={smsCode}
                        onChange={(e) => setSmsCode(e.target.value)}
                        required
                    />
                    {timer > 0 ? (
                        <p className="login_timer">
                            Вы можете запросить код повторно через {timer} секунд.
                        </p>
                    ) : (
                        <button
                            type="button"
                            className="login_resend-button"
                            onClick={handleResendCode}
                        >
                            Отправить код повторно
                        </button>
                    )}
                    <button
                        type="submit"
                        className="login_submit-button"
                    >
                        Подтвердить код
                    </button>
                    <button
                        type="button"
                        className="login_back-button"
                        onClick={handleReset}
                    >
                        Изменить номер
                    </button>
                </form>
            )}
        </div>
    );
};

export default PhoneVerificationForm;
