import {Sheet} from "react-modal-sheet";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAutoData} from "../../../hooks/useAutoData";
import {useGuarantees} from "../../../hooks/useGuarantees";
import {BlockedPhoneIcon, CloseIcon, PlusIcon, Requiredicon} from "../../../assets/svg/icons";
import InputMask from "react-input-mask";
import Dropdown from "../../../components/Dropdown";


const AddCarForm = ({isOpen, setOpen, updateCarList}) => {

    const navigate = useNavigate();
    const ref = useRef();

    const {
        marksLoading,
        marksData,
        marksError,
        fetchAutoMarks,

        modelsLoading,
        modelsData,
        modelsError,
        fetchAutoModels,

        yearsLoading,
        yearsData,
        yearsError,
        fetchAutoYears,


        modificationLoading,
        modificationData,
        modificationError,
        fetchAutoModification,


        createAutoLoading,
        createAutoData,
        createAutoError,
        fetchCreateAuto,

    } = useAutoData();


    // const [height, setHeight] = useState(window.innerHeight - 100);
    const [height, setHeight] = useState('90%');


    const [vinCode, setVinCode] = useState('');

    const [isFocus, setIsFocus] = useState('');

    const [vinCodeError, setVinCodeError] = useState(true);

    const [gosNumber, setGosNumber] = useState('');
    const [gosNumberError, setGosNumberError] = useState(true);


    const [selectedMark, setSelectedMark] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedModification, setSelectedModification] = useState(null);

    const [isFormValid, setIsFormValid] = useState(false);

    // Проверка валидности номера телефона
    useEffect(() => {
        console.log(height, 'height')
    }, []);


    useEffect(() => {
        if (createAutoData) {
            console.log(createAutoData, 'createAutoData')
            // navigate('/my-cars')
            updateCarList()
            setOpen(false);
        }
    }, [createAutoData]);

    useEffect(() => {
        if (createAutoError) {
            console.log(createAutoError, 'createAutoError')
        }
    }, [createAutoError]);

    // Проверка на заполненность всех полей
    useEffect(() => {
        const isFormComplete =   selectedMark && selectedModel && selectedYear
        setIsFormValid(isFormComplete);
    }, [ selectedMark, selectedModel, selectedYear]);


    // Маска гос. номера (X000XX77)

    // Функция для маски ввода номера (строго по X000XX77)
    const formatGosNumber = (value) => {
        let formatted = value.toUpperCase().replace(/[^A-Z0-9]/g, ''); // Убираем все, кроме букв A-Z и цифр 0-9

        if (formatted.length > 9) {
            formatted = formatted.slice(0, 9); // Ограничиваем до 8 символов
        }

        let newValue = '';
        for (let i = 0; i < formatted.length; i++) {
            if (i === 0) { // Первая буква (A-Z)
                if (/[A-Z]/.test(formatted[i])) {
                    newValue += formatted[i];
                }
            } else if (i > 0 && i < 4) { // Следующие три символа — цифры (0-9)
                if (/\d/.test(formatted[i])) {
                    newValue += formatted[i];
                }
            } else if (i >= 4 && i < 6) { // Две буквы (A-Z)
                if (/[A-Z]/.test(formatted[i])) {
                    newValue += formatted[i];
                }
            } else if (i >= 6 && i < 9) { // Две цифры (0-9)
                if (/\d/.test(formatted[i])) {
                    newValue += formatted[i];
                }
            }
        }

        return newValue;
    };

    // Валидация гос. номера
    const validateGosNumber = (value) => {
        const regex = /^[A-Z]{1}\d{3}[A-Z]{2}\d{3}$/;
        if (!regex.test(value)) {
            setGosNumberError(true);
        } else {
            setGosNumberError(false);
        }
    };

    // Обработчик изменения гос. номера
    const handleGosNumberChange = (e) => {
        let value = formatGosNumber(e.target.value);
        setGosNumber(value);
        validateGosNumber(value);
    };

    // Маска для VIN (17 символов)
    const formatVinCode = (value) => {
        return value.toUpperCase().replace(/[^A-HJ-NPR-Z0-9]/g, '').slice(0, 17);
    };

    // Валидация VIN
    const validateVinCode = (value) => {
        if (value.length !== 17) {
            setVinCodeError(true);
        } else {
            setVinCodeError(false);
        }
    };

    const handleVinCodeChange = (e) => {
        let value = formatVinCode(e.target.value);
        setVinCode(value);
        validateVinCode(value);
    };


    const handleCreateAuto = () => {

        if (!isFormValid ) {
            return false;
        }

        console.log("**ВИНКОД**", vinCode);
        console.log("**ГОС НОМЕР**", gosNumber);
        console.log("**МАРКА**", selectedMark);
        console.log("**МОДЕЛЬ**", selectedModel);
        console.log("**ГОД**", selectedYear);

        // modId, plate, vin, year
        fetchCreateAuto(selectedModel?.extId, gosNumber, vinCode, selectedYear?.label);

    };

    const snapTo = (i) => ref.current?.snapTo(i);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        // Очистка при размонтировании компонента
        return () => {
            document.body.style.overflow = "";
        };
    }, [isOpen]);


    const handleFocus = () => {

        const element = document.querySelector('.scrool-div');
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    };

    useEffect(() => {
        if (isFocus) {
            setTimeout(()=>{
                handleFocus();
            },1000)
        }
    }, [isFocus]);

    if (isOpen) {
        return (
            <div className={'extended-bottomshet-wrapper'} style={{height:height, maxHeight: 800}}>

                <div style={{display: "flex", justifyContent: "space-between", alignItems:'center', padding: '0 20px', paddingRight: 0}}>
                    <h1 style={{marginBottom: 0}} className={'cancel-order-bottomshet-title'}>Добавьте новый автомобиль</h1>
                    <CloseIcon
                        onClick={()=> {
                            setOpen(false)
                            // setSelectedReason(null)
                            // setSelectedFromReason(null)
                            // setCancelSuccess(false)
                        }}
                    />
                </div>

                <div className={'extended-bottomshet-container'} style={{paddingBottom: 0, display: 'block'}}>
                    {/*<h1 className={'extended-bottomshet-main-title'}>Добавьте новый автомобиль</h1>*/}

                    <Dropdown
                        isActive={marksData ? true : false}
                        defaultText={'Укажите марку авто'}
                        label={<div>Марка <Requiredicon/></div>}
                        options={marksData || []}
                        onOptionSelect={(selectedVal) => {
                            if (selectedVal) {
                                fetchAutoModels(selectedVal?.value);
                                setSelectedModel(null);
                                setSelectedYear(null);
                            }
                            setSelectedMark(selectedVal);
                        }}
                    />

                    <Dropdown
                        isActive={modelsData ? true : false}
                        defaultText={'Укажите модель авто'}
                        label={<div>Модель <Requiredicon/></div>}
                        options={modelsData || []}
                        onOptionSelect={(selectedVal) => {
                            if (selectedVal) {
                                fetchAutoYears(selectedVal?.value, selectedMark?.value);
                            }
                            setSelectedYear(null);
                            setSelectedModel(selectedVal);
                        }}
                    />

                    <Dropdown
                        isActive={yearsData ? true : false}
                        defaultText={'Укажите год выпуска'}
                        label={<div>Год выпуска <Requiredicon/></div>}
                        options={yearsData || []}
                        onOptionSelect={(selectedVal) => {
                            if (selectedVal) {
                                fetchAutoModification(selectedModel?.value, selectedMark?.value, selectedVal?.value );
                            }
                            setSelectedYear(selectedVal);
                        }}
                    />

                    {/*<Dropdown*/}
                    {/*    isActive={selectedYear ? true : false}*/}
                    {/*    defaultText={'Модификация'}*/}
                    {/*    label={<div>Модификация <Requiredicon/></div>}*/}
                    {/*    options={modificationData || []}*/}
                    {/*    onOptionSelect={(selectedVal) => {*/}
                    {/*        setSelectedModification(selectedVal);*/}
                    {/*    }}*/}
                    {/*/>*/}


                    <p className={'extended-subtitle'}>Данные автомобиля</p>

                    {/* Гос. номер */}
                    <div className="input-wrapper">
                        <label htmlFor="">
                            Госномер (латиницей)

                        </label>
                        <input
                            id="gosNumber"
                            className={`input ${gosNumberError ? 'input-custom-error' : ''}`}
                            placeholder="A000AA000"
                            type="text"
                            value={gosNumber}
                            onChange={handleGosNumberChange}
                            onFocus={()=>{
                                setIsFocus(true)
                            }}
                            onBlur={()=>{
                                setIsFocus(false)
                            }}
                        />
                        {gosNumberError && <span className="custom-error-message">{gosNumberError}</span>}
                    </div>

                    {/* VIN-код */}
                    <div className="extended-input-wrapper">
                        <input
                            id="vinCode"
                            className={`input ${vinCodeError ? 'input-custom-error' : ''}`}
                            placeholder="Введите VIN"
                            type="text"
                            value={vinCode}
                            onChange={handleVinCodeChange}
                            onFocus={()=>{
                                setIsFocus(true)
                            }}
                            onBlur={()=>{
                                setIsFocus(false)
                            }}
                        />
                        {vinCodeError && <span className="custom-error-message">{vinCodeError}</span>}
                    </div>

                    {createAutoError &&
                        <p style={{color: '#f15458', fontSize: 14}}>
                            Произошла ошибка добавления авто, перепроверьте данные и попробуйте еще раз
                        </p>
                    }

                    {isFocus && window.Telegram?.WebApp?.platform == 'ios' &&

                        <div className={'bottom-btns-wrapper2'} style={{ paddingBottom: 15}}>
                            <button
                                style={{flex: 1}}
                                onClick={() => {
                                    setOpen(false)
                                    setTimeout(() => {
                                        setHeight(window.innerHeight - 100)
                                    }, 1000)
                                }}
                                className={'simple-btn'}
                            >
                                Отмена
                            </button>

                            <button
                                style={{flex: 1, opacity: isFormValid  ? 1 : 0.5}}
                                onClick={() => {
                                    handleCreateAuto()
                                }}
                                className={'red-btn'}
                            >
                                <PlusIcon/>
                                Добавить авто
                            </button>
                        </div>
                    }

                    { window.Telegram?.WebApp?.platform == 'ios' ?
                        <div style={{ marginTop: 280, width: '100%', height: 100}} className={'scrool-div'}></div>
                        :
                        // <div style={{ marginTop: 180, width: '100%', height: 100}} className={'scrool-div'}></div>
                        <div style={{ marginTop: 20, width: '100%', height: 100}} className={'scrool-div'}></div>
                    }

                </div>


                {(!isFocus || window.Telegram?.WebApp?.platform != 'ios') &&
                    <div className={'bottom-btns-wrapper'} style={{padding: 15, paddingBottom: 15}}>
                        <button
                            style={{flex: 1}}
                            onClick={() => {
                                setOpen(false)
                                setTimeout(() => {
                                    setHeight(window.innerHeight - 100)
                                }, 1000)
                            }}
                            className={'simple-btn'}
                        >
                            Отмена
                        </button>

                        <button
                            style={{flex: 1, opacity: isFormValid  ? 1 : 0.5}}
                            onClick={() => {
                                handleCreateAuto()
                            }}
                            className={'red-btn'}
                        >
                            <PlusIcon/>
                            Добавить авто
                        </button>
                    </div>
                }

            </div>

        )
    }

    // return (
    // <Sheet
    //     isOpen={isOpen}
    //     onClose={() => setOpen(false)}
    //     initialSnap={0}
    //     snapPoints={[height, 400, 200, 0]}
    // >
    //     <Sheet.Container>
    //
    //         <Sheet.Header />
    //         <Sheet.Content>
    //
    //             <div className={'extended-bottomshet-wrapper'}>
    //                 <div className={'extended-bottomshet-container'}>
    //                     <h1 className={'extended-bottomshet-main-title'}>Добавьте новый автомобиль</h1>
    //
    //                     <Dropdown
    //                         isActive={marksData ? true : false}
    //                         defaultText={'Укажите марку авто'}
    //                         label={<div>Марка <Requiredicon/></div>}
    //                         options={marksData || []}
    //                         onOptionSelect={(selectedVal) => {
    //                             if (selectedVal) {
    //                                 fetchAutoModels(selectedVal?.value);
    //                                 setSelectedModel(null);
    //                                 setSelectedYear(null);
    //                             }
    //                             setSelectedMark(selectedVal);
    //                         }}
    //                     />
    //
    //                     <Dropdown
    //                         isActive={modelsData ? true : false}
    //                         defaultText={'Укажите модель авто'}
    //                         label={<div>Модель <Requiredicon/></div>}
    //                         options={modelsData || []}
    //                         onOptionSelect={(selectedVal) => {
    //                             if (selectedVal) {
    //                                 fetchAutoYears(selectedVal?.value, selectedMark?.value);
    //                             }
    //                             setSelectedYear(null);
    //                             setSelectedModel(selectedVal);
    //                         }}
    //                     />
    //
    //                     <Dropdown
    //                         isActive={yearsData ? true : false}
    //                         defaultText={'Укажите год выпуска'}
    //                         label={<div>Год выпуска <Requiredicon/></div>}
    //                         options={yearsData || []}
    //                         onOptionSelect={(selectedVal) => {
    //                             if (selectedVal) {
    //                                 fetchAutoModification(selectedModel?.value, selectedMark?.value, selectedVal?.value );
    //                             }
    //                             setSelectedYear(selectedVal);
    //                         }}
    //                     />
    //
    //                     {/*<Dropdown*/}
    //                     {/*    isActive={selectedYear ? true : false}*/}
    //                     {/*    defaultText={'Модификация'}*/}
    //                     {/*    label={<div>Модификация <Requiredicon/></div>}*/}
    //                     {/*    options={modificationData || []}*/}
    //                     {/*    onOptionSelect={(selectedVal) => {*/}
    //                     {/*        setSelectedModification(selectedVal);*/}
    //                     {/*    }}*/}
    //                     {/*/>*/}
    //
    //
    //                     <p className={'extended-subtitle'}>Данные автомобиля</p>
    //
    //                     {/* Гос. номер */}
    //                     <div className="input-wrapper">
    //                         <label htmlFor="">
    //                             Госномер (латиницей)
    //
    //                         </label>
    //                         <input
    //                             id="gosNumber"
    //                             className={`input ${gosNumberError ? 'input-custom-error' : ''}`}
    //                             placeholder="A000AA000"
    //                             type="text"
    //                             value={gosNumber}
    //                             onChange={handleGosNumberChange}
    //                         />
    //                         {gosNumberError && <span className="custom-error-message">{gosNumberError}</span>}
    //                     </div>
    //
    //                     {/* VIN-код */}
    //                     <div className="extended-input-wrapper">
    //                         <input
    //                             id="vinCode"
    //                             className={`input ${vinCodeError ? 'input-custom-error' : ''}`}
    //                             placeholder="Введите VIN"
    //                             type="text"
    //                             value={vinCode}
    //                             onChange={handleVinCodeChange}
    //                         />
    //                         {vinCodeError && <span className="custom-error-message">{vinCodeError}</span>}
    //                     </div>
    //
    //                     {createAutoError &&
    //                         <p style={{color: '#f15458', fontSize: 14}}>
    //                             Произошла ошибка добавления авто, перепроверьте данные и попробуйте еще раз
    //                         </p>
    //                     }
    //                 </div>
    //
    //
    //                 <div className={'bottom-btns-wrapper'} style={{padding: 15, paddingBottom: 15}}>
    //                     <button
    //                         style={{flex: 1}}
    //                         onClick={() => {
    //                             setOpen(false)
    //                             setTimeout(() => {
    //                                 setHeight(window.innerHeight - 100)
    //                             }, 1000)
    //                         }}
    //                         className={'simple-btn'}
    //                     >
    //                         Отмена
    //                     </button>
    //
    //                     <button
    //                         style={{flex: 1, opacity: isFormValid  ? 1 : 0.5}}
    //                         onClick={() => {
    //                             handleCreateAuto()
    //                         }}
    //                         className={'red-btn'}
    //                     >
    //                         <PlusIcon/>
    //                         Добавить авто
    //                     </button>
    //                 </div>
    //             </div>
    //
    //         </Sheet.Content>
    //     </Sheet.Container>
    //     <Sheet.Backdrop/>
    // </Sheet>

    // height:height, maxHeight: 800
    // )
}

export default AddCarForm
