// import React, { useState, useEffect } from 'react';
// import { DiscountActiveIcon, DiscountNoActiveIcon } from '../../assets/svg/icons';
//
// const CountdownTimer = ({phone}) => {
//
//     // const initialTime = 4 * 60 * 60;
//     const initialTime = 10;
//
//     const [timeLeft, setTimeLeft] = useState(0);
//     const [startTime, setStartTime] = useState(null);
//     const [isLoading, setIsLoading] = useState(true);
//
//     // Ключ в localStorage, зависящий от номера телефона
//     const timerKey = `timerStart_${phone}`;
//
//     // Эффект, который читает localStorage и выставляет время один раз при маунте
//     useEffect(() => {
//         const savedStartTime = localStorage.getItem(timerKey);
//         if (savedStartTime) {
//             const startTimeNum = parseInt(savedStartTime, 10);
//             setStartTime(startTimeNum);
//
//             const currentTime = Date.now();
//             const elapsedTime = Math.floor((currentTime - startTimeNum) / 1000);
//             const newTimeLeft = initialTime - elapsedTime;
//             setTimeLeft(newTimeLeft > 0 ? newTimeLeft : 0);
//         } else {
//             // Если таймер не запущен, устанавливаем время по умолчанию (4 часа)
//             setTimeLeft(initialTime);
//         }
//         setIsLoading(false);
//     }, [timerKey]);
//
//     // Запускаем интервал, если таймер уже был запущен
//     useEffect(() => {
//         if (!startTime) return;
//
//         const timerInterval = setInterval(() => {
//             const currentTime = Date.now();
//             const elapsedTime = Math.floor((currentTime - startTime) / 1000);
//             const newTimeLeft = initialTime - elapsedTime;
//             setTimeLeft(newTimeLeft > 0 ? newTimeLeft : 0);
//
//             if (newTimeLeft <= 0) {
//                 localStorage.removeItem(timerKey);
//                 clearInterval(timerInterval);
//             }
//         }, 1000);
//
//         return () => clearInterval(timerInterval);
//     }, [startTime, timerKey]);
//
//     // Форматируем секунды в HH:MM:SS
//     const formatTime = (seconds) => {
//         const hrs = Math.floor(seconds / 3600).toString().padStart(2, '0');
//         const mins = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
//         const secs = (seconds % 60).toString().padStart(2, '0');
//         return `${hrs}:${mins}:${secs}`;
//     };
//
//     // Запуск таймера
//     const handleStartTimer = () => {
//         const currentTime = Date.now();
//         setStartTime(currentTime);
//         // Сохраняем время старта в localStorage с учётом телефона
//         localStorage.setItem(timerKey, currentTime.toString());
//         setTimeLeft(initialTime);
//     };
//
//     // Пока идёт чтение localStorage, показываем некий лоадер
//     if (isLoading) {
//         return <div>Загрузка...</div>;
//     }
//
//     // Проверяем, запущен ли таймер — есть ли в localStorage соответствующее значение
//     const isStartedTimer = !!localStorage.getItem(timerKey);
//
//     return (
//         <div>
//             {isStartedTimer ? (
//                 <button className={'main-discount-btn-active'}>
//                     <div className={'main-discount-btn-left'}>
//                         <DiscountActiveIcon />
//                     </div>
//                     <div className={'main-discount-btn-right'}>
//                         <span>Скидки активированы</span>
//                         <span>Осталось: {formatTime(timeLeft)}</span>
//                     </div>
//                 </button>
//             ) : (
//                 <button onClick={handleStartTimer} className={'main-discount-btn'}>
//                     <div className={'main-discount-btn-left'}>
//                         <DiscountNoActiveIcon />
//                     </div>
//                     <div className={'main-discount-btn-right'}>
//                         <span>Активировать скидки в центре</span>
//                         <span>по дисконтной карте на 4 часа</span>
//                     </div>
//                 </button>
//             )}
//         </div>
//     );
// };
//
// export default CountdownTimer;


import React, { useState, useEffect } from 'react';
import { DiscountActiveIcon, DiscountNoActiveIcon } from '../../assets/svg/icons';

const CountdownTimer = ({ phone }) => {
    const initialTime = 4 * 60 * 60;
    // const initialTime = 10;

    const [timeLeft, setTimeLeft] = useState(initialTime);
    const [startTime, setStartTime] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // Здесь храним «запущен ли таймер» (true/false)
    const [isStarted, setIsStarted] = useState(false);

    // Ключ в localStorage, зависящий от номера телефона
    const timerKey = `timerStart_${phone}`;

    useEffect(() => {
        const savedStartTime = localStorage.getItem(timerKey);
        if (savedStartTime) {
            const startTimeNum = parseInt(savedStartTime, 10);
            setStartTime(startTimeNum);

            const currentTime = Date.now();
            const elapsedTime = Math.floor((currentTime - startTimeNum) / 1000);
            const newTimeLeft = initialTime - elapsedTime;

            if (newTimeLeft > 0) {
                setTimeLeft(newTimeLeft);
                setIsStarted(true);  // Таймер в процессе
            } else {
                setTimeLeft(0);
                setIsStarted(false); // Время вышло
                localStorage.removeItem(timerKey); // на всякий случай очистим
            }
        } else {
            // Если таймер не запущен, просто выставим значения
            setTimeLeft(initialTime);
            setIsStarted(false);
        }
        setIsLoading(false);
    }, [timerKey, initialTime]);

    useEffect(() => {
        if (!startTime) return;

        const timerInterval = setInterval(() => {
            const currentTime = Date.now();
            const elapsedTime = Math.floor((currentTime - startTime) / 1000);
            const newTimeLeft = initialTime - elapsedTime;

            if (newTimeLeft > 0) {
                setTimeLeft(newTimeLeft);
            } else {
                setTimeLeft(0);
                setIsStarted(false);
                localStorage.removeItem(timerKey);
                clearInterval(timerInterval);
            }
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [startTime, initialTime, timerKey]);

    // Форматируем секунды в HH:MM:SS
    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600).toString().padStart(2, '0');
        const mins = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
        const secs = (seconds % 60).toString().padStart(2, '0');
        return `${hrs}:${mins}:${secs}`;
    };

    // Запуск таймера
    const handleStartTimer = () => {
        const currentTime = Date.now();
        setStartTime(currentTime);
        localStorage.setItem(timerKey, currentTime.toString());

        setTimeLeft(initialTime);
        setIsStarted(true);
    };

    if (isLoading) {
        return <div>Загрузка...</div>;
    }

    return (
        <div>
            {isStarted ? (
                <button className="main-discount-btn-active">
                    <div className="main-discount-btn-left">
                        <DiscountActiveIcon />
                    </div>
                    <div className="main-discount-btn-right">
                        <span>Скидки активированы</span>
                        <span>Осталось: {formatTime(timeLeft)}</span>
                    </div>
                </button>
            ) : (
                <button onClick={handleStartTimer} className="main-discount-btn">
                    <div className="main-discount-btn-left">
                        <DiscountNoActiveIcon />
                    </div>
                    <div className="main-discount-btn-right">
                        <span>Активировать скидки в центре</span>
                        <span>по дисконтной карте на 4 часа</span>
                    </div>
                </button>
            )}
        </div>
    );
};

export default CountdownTimer;
