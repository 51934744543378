import React, {useState} from 'react';
import {Link, Link as RouterLink, useNavigate} from "react-router-dom";
// import navDots from "../../assets/img/icons/nav-dots.svg";
import '../../assets/css/style.css';
import useTranslation from "../../hooks/useTranslation";
import {BurgerMenu, CloseIcon} from "../../assets/svg/icons"; // Assuming you have a CSS file for styles

const Select = () => {

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const {translation} = useTranslation();

    return (
        <>
            <div  onClick={()=>{setIsOpen(true)}} className="nav__item" >
                <BurgerMenu/>
            </div>



            {isOpen &&

                <div className={'absolute-menu-wrapper'}>

                    <div className={'menu-wrapper-header'}>
                        <h1>Меню</h1>

                        <div
                            onClick={() => {
                                setIsOpen(false)
                            }}
                        >
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className={'menu-items-wrapper'}>


                        <Link to={'/main'} className={'menu-item'}>
                            Главная
                        </Link>

                        <Link to={'/my-profile'} className={'menu-item'}>
                            Мои данные
                        </Link>

                        <Link to={'/my-cars'} className={'menu-item'}>
                            Мои авто
                        </Link>


                        <Link to={'/my-orders'} className={'menu-item'}>
                            Мои заказы
                        </Link>

                        <Link to={'/registrations-for-services'} className={'menu-item'}>
                            Мои услуги
                        </Link>

                        <Link to={'/wheel-storage-list'} className={'menu-item'}>
                            Хранение колёс
                        </Link>

                        <Link to={'/my-discount'} className={'menu-item'}>
                            Дисконтная программа
                        </Link>

                        <Link to={'/extended-warranty-list'} className={'menu-item'}>
                            Расширенная гарантия
                        </Link>

                        <Link to={'/my-purchases-list'} className={'menu-item'} style={{borderBottom:0}}>
                            Мои покупки
                        </Link>

                        <Link to={'/settings'} className={'menu-item'} style={{borderBottom:0}}>
                           Настройки
                        </Link>


                    </div>

                    <button
                        onClick={() => {
                            setIsOpen(false)
                        }}
                        className={'close-menu-btn'}
                    >
                        Скрыть меню
                    </button>


                </div>

            }

        </>
    );

};

export default Select;
