import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useUserOrders} from "../../../../../hooks/useUserOrders";
import {CloseIcon} from "../../../../../assets/svg/icons";


const CancelOrderBottomSheet = ({isOpen, setOpen, selectedSingleData}) => {
    const navigate = useNavigate();
    const ref = useRef();
    const {
        cancelOrder,
        cancelOrderLoading,
        cancelOrderError,
        fetchCancelOrder
    } = useUserOrders();


    const [isFocus, setIsFocus] = useState(false)

    const [selectedReason, setSelectedReason] = useState(null)
    const [selectedFromReason, setSelectedFromReason] = useState(null)
    const [height, setHeight] = useState('90%');
    // const [height, setHeight] = useState(170);
    const [textareaValue, setTextareaValue] = useState(''); // Состояние для хранения значения textarea

    const [cancelSuccess, setCancelSuccess] = useState(false)

    const [reasonsList, setReasonsList] = useState([
        {id: 1, title: 'Не устроили сроки', fromReason: 'DEADLINE'},
        {id: 2, title: 'Уже купил(а) у вас', fromReason: "ALREADY"},
        {id: 3, title: 'Купил(а) в другой компании', fromReason: "ANOTHER"},
        {id: 4, title: 'Ошибка с выбором', fromReason: "SELECTION_ERROR"},
        {id: 5, title: 'Проблемы с кредитом/рассрочкой', fromReason: "PROBLEM_CREDIT"},
        {id: 6, title: 'Не устроила цена', fromReason: "VERY_EXPENSIVE"},
        {id: 7, title: 'Передумал покупать', fromReason: "CHANGED_MY_MIND"},
    ]);


    const handleTextareaChange = (e) => {
        setTextareaValue(e.target.value); // Обновляем состояние при изменении текста
    };


    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        // Очистка при размонтировании компонента
        return () => {
            document.body.style.overflow = "";
        };
    }, [isOpen]);

    useEffect(()=>{
        if (cancelOrder) {
            console.log(cancelOrder, 'cancelOrder')
            setCancelSuccess(true);
            setHeight(170)
        }
    }, [cancelOrder])

    useEffect(()=>{
        if (cancelOrderError) {
            console.log(cancelOrderError, 'cancelOrderError')
        }
    }, [cancelOrderError])

    //
    //
    // const handleFocus = () => {
    //     const element = document.querySelector('.cancel-order-bottomshet-container');
    //     if (element) {
    //         // element.scrollIntoView({
    //         //     behavior: "smooth",
    //         //     block: "end",
    //         // });
    //
    //         element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    //
    //     }
    // };

    const handleFocus = () => {

        const element = document.querySelector('.scrool-div');
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    };

    useEffect(() => {
        if (isFocus) {
            setTimeout(()=>{
                handleFocus();
            },1000)
        }
    }, [isFocus]);

    if (isOpen) {

        return (
            <div className={'cancel-order-bottomshet-main-wrapper'} style={{height:height, maxHeight: 800}}>

                <div className={'cancel-order-bottomshet-wrapper'} style={{padding: 0}}>
                    {!cancelSuccess ?

                        <>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems:'center', padding: '0 20px', paddingRight: 0}}>
                                <h1 style={{marginBottom: 0}} className={'cancel-order-bottomshet-title'}>Отмена заказа</h1>
                                <CloseIcon
                                    onClick={()=> {
                                        setOpen(false)
                                        setSelectedReason(null)
                                        setSelectedFromReason(null)
                                        setCancelSuccess(false)
                                    }}
                                />
                            </div>

                            <div
                                className={'cancel-order-bottomshet-container'}
                                style={{
                                    padding: 16,
                                    // paddingBottom: isFocus ? 200 : 0
                                }}
                            >


                                <h2 className={'cancel-order-bottomshet-subtitle'}>Выберите причину</h2>


                                <div className={'reasons-list-wrapper'}>
                                    {reasonsList?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => {
                                                    setSelectedReason(item?.id);
                                                    setSelectedFromReason(item?.fromReason)
                                                }}
                                                className={'reasons-list-item'}
                                            >
                                                <div className={'custom-checkbox'}>
                                                    {selectedReason == item?.id &&
                                                        <div className={'custom-checkbox-active'}>

                                                        </div>
                                                    }
                                                </div>

                                                <span>{item?.title}</span>
                                            </div>
                                        )
                                    })}
                                </div>

                                {selectedReason &&
                                    <>
                                        <textarea
                                            // style={{marginBottom: 100}}
                                            style={
                                                window.Telegram?.WebApp?.platform == 'ios' ? {marginBottom: isFocus ? 200 : 100} : {marginBottom: 50}
                                            }
                                            onFocus={()=>{
                                                setIsFocus(true)
                                            }}
                                            onBlur={()=>{
                                                setIsFocus(false)
                                            }}
                                            className={'cancel-order-textarea'}
                                            placeholder={'Расскажите подробнее'} name="" id="" cols="30"
                                            rows="8" value={textareaValue} // Связываем значение textarea с состоянием
                                            onChange={handleTextareaChange} // Обработчик изменения текста
                                        />
                                        <div style={{width: '100%', height: 130}}>

                                        </div>
                                    </>

                                }

                                <div style={{marginBottom: 10}} className={'scrool-div'}></div>

                            </div>
                            <div className={'bottom-btns-wrapper'} >
                                <button
                                    style={{flex: 1}}
                                    onClick={() => {

                                        setCancelSuccess(false);
                                        setOpen(false)
                                        setTimeout(()=>{
                                            setHeight(window.innerHeight-100)
                                            setSelectedReason(null)
                                        }, 1000)

                                    }}
                                    className={'simple-btn'}
                                >
                                    Закрыть
                                </button>
                                <button
                                    style={{opacity: selectedReason && !cancelOrderLoading ? 1 : 0.5}}
                                    onClick={() => {

                                        if (!cancelOrderLoading) {
                                            fetchCancelOrder(selectedSingleData?.orderRn, selectedFromReason, textareaValue)
                                            console.log(selectedSingleData?.orderRn, 'orderNumber')
                                            console.log(selectedFromReason, 'selectedFromReason')
                                            console.log(textareaValue, 'textareaValue')
                                        }

                                    }}

                                    className={'red-btn'}
                                >
                                    Отменить заказ
                                </button>
                            </div>
                        </>

                        :

                        <div className={'cancel-success-wrapper'}>
                            <div className={'cancel-success-message'}>
                                Спасибо! Мы передали информацию менеджеру
                            </div>
                            <div className={'bottom-btns-wrapper'} >
                                <button
                                    style={{flex: 1}}
                                    onClick={() => {
                                        setCancelSuccess(false);
                                        setOpen(false)
                                        setTimeout(()=>{
                                            setHeight(window.innerHeight-100)
                                            setSelectedReason(null)
                                        }, 1000)

                                        navigate(-1)
                                    }}
                                    className={'simple-btn'}
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>

                    }

                </div>

            </div>

        )

    }

//     return (
//         <Sheet
//             isOpen={isOpen}
//             onClose={() => setOpen(false)}
//             initialSnap={0}
//             snapPoints={[height, 200, 400, 0]}
//         >
//             <Sheet.Container>
//
//                 <Sheet.Header />
//                 <Sheet.Content>
//
//                     <div className={'cancel-order-bottomshet-wrapper'} style={{padding: 0}}>
//                         {!cancelSuccess ?
//
//                             <>
//                                 <div className={'cancel-order-bottomshet-container'} style={{padding: 16}}>
//
//                                     <h1 className={'cancel-order-bottomshet-title'}>Отмена заказа</h1>
//                                     <h2 className={'cancel-order-bottomshet-subtitle'}>Выберите причину</h2>
//
//
//                                     <div className={'reasons-list-wrapper'}>
//                                         {reasonsList?.map((item, index) => {
//                                             return (
//                                                 <div
//                                                     key={index}
//                                                     onClick={() => {
//                                                         setSelectedReason(item?.id);
//                                                         setSelectedFromReason(item?.fromReason)
//                                                     }}
//                                                     className={'reasons-list-item'}
//                                                 >
//                                                     <div className={'custom-checkbox'}>
//                                                         {selectedReason == item?.id &&
//                                                             <div className={'custom-checkbox-active'}>
//
//                                                             </div>
//                                                         }
//                                                     </div>
//
//                                                     <span>{item?.title}</span>
//                                                 </div>
//                                             )
//                                         })}
//                                     </div>
//
//                                     {selectedReason &&
//                                         <textarea
//                                             style={{marginBottom: isFocus ? 200 : 0}}
//                                             onFocus={()=>{
//                                                 setIsFocus(true)
//                                             }}
//                                             onBlur={()=>{
//                                                 setIsFocus(false)
//                                             }}
//                                             className={'cancel-order-textarea'}
//                                             placeholder={'Расскажите подробнее'} name="" id="" cols="30"
//                                             rows="10" value={textareaValue} // Связываем значение textarea с состоянием
//                                             onChange={handleTextareaChange} // Обработчик изменения текста
//                                         />
//                                     }
//
//                                 </div>
//                                 <div className={'bottom-btns-wrapper'} >
//                                 <button
//                                         style={{flex: 1}}
//                                         onClick={() => {
//
//                                             setCancelSuccess(false);
//                                             setOpen(false)
//                                             setTimeout(()=>{
//                                                 setHeight(window.innerHeight-100)
//                                                 setSelectedReason(null)
//                                             }, 1000)
//
//                                         }}
//                                         className={'simple-btn'}
//                                     >
//                                         Закрыть
//                                     </button>
//                                     <button
//                                         style={{opacity: selectedReason && !cancelOrderLoading ? 1 : 0.5}}
//                                         onClick={() => {
//
//                                             if (!cancelOrderLoading) {
//                                                 fetchCancelOrder(selectedSingleData?.orderRn, selectedFromReason, textareaValue)
//                                                 console.log(selectedSingleData?.orderRn, 'orderNumber')
//                                                 console.log(selectedFromReason, 'selectedFromReason')
//                                                 console.log(textareaValue, 'textareaValue')
//                                             }
//
//                                         }}
//
//                                         className={'red-btn'}
//                                     >
//                                         Отменить заказ
//                                     </button>
//                                 </div>
//                             </>
//
//                             :
//
//                             <div className={'cancel-success-wrapper'}>
//                                 <div className={'cancel-success-message'}>
//                                     Спасибо! Мы передали информацию менеджеру
//                                 </div>
//                                 <div className={'bottom-btns-wrapper'} style={{padding: 0, paddingBottom: 0}}>
//                                     <button
//                                         style={{flex: 1}}
//                                         onClick={() => {
//                                             setCancelSuccess(false);
//                                             setOpen(false)
//                                             setTimeout(()=>{
//                                                 setHeight(window.innerHeight-100)
//                                                 setSelectedReason(null)
//                                             }, 1000)
//
//                                             navigate(-1)
//                                         }}
//                                         className={'simple-btn'}
//                                     >
//                                         Закрыть
//                                     </button>
//                                 </div>
//                             </div>
//
//                         }
//
//                     </div>
//
//                 </Sheet.Content>
//             </Sheet.Container>
//             <Sheet.Backdrop/>
// </Sheet>
// )
}

export default CancelOrderBottomSheet
