import { useState, useEffect } from 'react';
import {getDiscount, getUserCard} from '../utils/api/cardApi'; // Импорт функции получения профиля

const useMyCard = () => {
    const [cardAnswer, setCardAnswer] = useState(null); // Состояние для хранения профиля
    const [loadingCard, setLoadingCard] = useState(false); // Состояние для индикации загрузки
    const [errorCard, setErrorCard] = useState(null); // Состояние для хранения ошибок

    // Функция для получения профиля
    const fetchCard = async (phone) => {
        setLoadingCard(true);
        setErrorCard(null);
        // const phone = localStorage.getItem('phone');
        phone = phone.toString()
        try {
            // const data = await getUserCard('+7(985)385-21-38'); // Получение профиля через API
            const data = await getUserCard(phone); // Получение профиля через API
            setCardAnswer(data); // Сохранение профиля в состоянии
        } catch (err) {
            setErrorCard(err); // Сохранение ошибки в состоянии
        } finally {
            setLoadingCard(false); // Сброс состояния загрузки
        }
    };

    const [discountAnswer, setDiscountAnswer] = useState(null); // Состояние для хранения профиля
    const [loadingDiscount, setLoadingDiscount] = useState(false); // Состояние для индикации загрузки
    const [errorDiscount, setErrorDiscount] = useState(null); // Состояние для хранения ошибок

    // Функция для получения профиля
    const fetchDiscount = async () => {
        setLoadingDiscount(true);
        setErrorDiscount(null);

        try {
            const data = await getDiscount(); // Получение профиля через API
            setDiscountAnswer(data); // Сохранение профиля в состоянии
        } catch (err) {
            setErrorDiscount(err); // Сохранение ошибки в состоянии
        } finally {
            setLoadingDiscount(false); // Сброс состояния загрузки
        }
    };

    useEffect(() => {
        fetchDiscount()
    }, []);

    return {
        cardAnswer,
        loadingCard,
        errorCard,
        fetchCard , // Функция для обновления профиля по запросу

        discountAnswer,
        loadingDiscount,
        errorDiscount,
        fetchDiscount ,
    };
};

export default useMyCard;
