import { useState, useEffect } from 'react';
import {getUserProfile, updateUserProfileApi} from '../utils/api/profileApi';
import {getUserSettingsApi, updateUserSettingsApi} from "../utils/api/settingsApi"; // Импорт функции получения профиля

const useSettings = () => {
    const [settingsAnswer, setSettingsAnswer] = useState(null); // Состояние для хранения профиля
    const [settingsLoad, setSettingsLoad] = useState(false); // Состояние для индикации загрузки
    const [settingsError, setSettingsError] = useState(null); // Состояние для хранения ошибок

    const fetchUserSettings = async () => {
        setSettingsLoad(true);
        setSettingsError(null);
        try {
            const data = await getUserSettingsApi();
            setSettingsAnswer(data);
        } catch (err) {
            setSettingsError(err);
        } finally {
            setSettingsLoad(false);
        }
    };


    const [settingsUpdateAnswer, setSettingsUpdateAnswer] = useState(null); // Состояние для хранения профиля
    const [settingsUpdateLoad, setSettingsUpdateLoad] = useState(false); // Состояние для индикации загрузки
    const [settingsUpdateError, setSettingsUpdateError] = useState(null); // Состояние для хранения ошибок


    // Функция для получения профиля
    const fetchUpdateUserSettings = async (payload) => {

        setSettingsUpdateLoad(true);
        setSettingsUpdateError(null);
        try {
            const data = await updateUserSettingsApi(payload); // Получение профиля через API
            setSettingsUpdateAnswer(data); // Сохранение профиля в состоянии
        } catch (err) {
            setSettingsUpdateError(err); // Сохранение ошибки в состоянии
        } finally {
            setSettingsUpdateLoad(false); // Сброс состояния загрузки
        }
    };


    return {
        settingsAnswer,
        settingsLoad,
        settingsError,
        fetchUserSettings,


        settingsUpdateAnswer,
        settingsUpdateLoad,
        settingsUpdateError,
        fetchUpdateUserSettings
    };
};

export default useSettings;
